import { UserContext } from '@application/contexts';
import { UserContextProps } from '@application/contexts/UserContext';
import { ReportAggregation, SubscriptionPlan, Tenant, TenantSubscription, User, UserRole, UserStatus } from '@domain/graphql.types';
import useMeQuery from '@domain/users/useMeQuery';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

type UserProviderProps = PropsWithChildren<unknown>;

const UserProvider = ({ children }: UserProviderProps) => {
  const [initializationCompleted, setInitializationCompleted] = useState(false);
  const [hasFinishedOnboarding, setFinishedOnboarding] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [role, setRole] = useState<UserRole | undefined>();
  const [tenant, setTenant] = useState<Tenant | undefined>();
  const [tenantSubscription, setTenantSubscription] = useState<TenantSubscription | undefined>();
  const [isInvitedUser, setIsInvitedUser] = useState(false);
  const [isOnFreePlan, setIsOnFreePlan] = useState(false);
  const [version, setVersion] = useState<string | null>(null);

  const { data, fetching, error, reexecuteQuery } = useMeQuery({ pause: true });
  const initializeUser = useCallback(() => {
    reexecuteQuery();
  }, [reexecuteQuery]);

  useEffect(() => {
    if (!data?.me?.user) {
      return;
    }

    setInitializationCompleted(!!data?.me?.user);
    setUser({ ...data.me.user });
    setRole(data.me.user.role || undefined);
    setFinishedOnboarding(data.me.user.hasCompletedOnboarding);
    setIsInvitedUser(data.me.user.status === UserStatus.Invited);
    setTenantSubscription(data.me.user.defaultTenant?.subscription || undefined);
    setTenant(data.me.user.defaultTenant || undefined);
    setIsOnFreePlan(data.me.user.defaultTenant?.subscription?.plan === SubscriptionPlan.Free);
    setVersion(data.me.user.version);
  }, [data]);

  const finishOnboarding = useCallback((updatedUser: User) => {
    setUser(updatedUser);
    setRole(updatedUser.role || undefined);
    setFinishedOnboarding(updatedUser.hasCompletedOnboarding);
    setIsInvitedUser(updatedUser.status === UserStatus.Invited);
    setTenantSubscription(updatedUser.defaultTenant?.subscription || undefined);
    setTenant(updatedUser.defaultTenant || undefined);
    setIsOnFreePlan(updatedUser.defaultTenant?.subscription?.plan === SubscriptionPlan.Free);
    setVersion(updatedUser.version);
  }, []);

  const hasSupportForAggregation = useCallback(
    (aggregation: ReportAggregation) => {
      if (!tenantSubscription?.allowedAggregations.length) {
        return false;
      }

      return tenantSubscription.allowedAggregations.includes(aggregation);
    },
    [tenantSubscription],
  );

  const value = useMemo(
    (): UserContextProps => ({
      error,
      finishOnboarding,
      initializationCompleted,
      initializeUser,
      isEmailVerified: !!user?.isEmailVerified,
      isInvitedUser,
      isLoading: fetching,
      hasFinishedOnboarding,
      hasSupportForAggregation,
      refreshUser: reexecuteQuery,
      user,
      role,
      tenantSubscription,
      tenant,
      isOnFreePlan,
      version,
    }),
    [
      error,
      initializationCompleted,
      isInvitedUser,
      finishOnboarding,
      hasFinishedOnboarding,
      hasSupportForAggregation,
      initializeUser,
      role,
      user,
      fetching,
      reexecuteQuery,
      tenantSubscription,
      tenant,
      isOnFreePlan,
      version,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
