import { CombinedError } from 'urql';

type OriginalError = {
  message: string;
  statusCode: number;
  cause?: Record<string, unknown>;
};

export const extractErrorCodes = (error: CombinedError | undefined): { code: string; message: string; status: number; error: OriginalError }[] =>
  error?.graphQLErrors.map((e) => ({
    code: e.extensions.code as string,
    status: e.extensions.status as number,
    message: e.message,
    error: e.extensions.originalError as OriginalError,
  })) || [];
