import { Stack } from '@mui/material';
import DownloadReportModal from './components/DownloadReportDialog';
import MainContent from './components/MainContent';
import SearchBar from './components/SearchBar';
import SiteLookupProvider from './context/SiteLookupProvider';
import useSiteLookup from './hooks/useSiteLookup';

const SiteLookup = () => {
  const {
    actions: { downloadReport, updateFilter },
    state: { filters, isDownloadingSitesReport, isUserOnFreePlan, remainingSearches },
  } = useSiteLookup();

  return (
    <>
      <Stack height="100%">
        <SiteLookupProvider>
          <SearchBar
            onFilterChange={updateFilter}
            filters={filters}
            handleDownloadReport={downloadReport}
            isUserOnFreePlan={isUserOnFreePlan}
            remainingSearches={remainingSearches}
          />

          <MainContent filters={filters} />
        </SiteLookupProvider>
      </Stack>

      <DownloadReportModal open={isDownloadingSitesReport} />
    </>
  );
};

export default SiteLookup;
