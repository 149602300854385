import { createContext } from 'react';

type FeedbackContextProps = {
  feedback?: React.ReactNode;
  setFeedback: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const FeedbackContext = createContext<FeedbackContextProps>({ feedback: undefined, setFeedback: () => {} });

export default FeedbackContext;
