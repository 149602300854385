import { extractErrorCodes } from '@application/utils/urql-utils';
import { useInviteTenantUserMutation } from '@domain/tenantusers';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { INVITE_TENANT_USER_SCHEMA, InviteTenantUserFormFields } from '../invite-tenant-user-modal/InviteTenantUserForm.schema';
import useTenantInvitationsList from '../tenant-invitations-list/useTenantInvitationsList';
import EmptyState from '../TenantInvitationsEmptyState';
import { ErrorSnackbar, SuccessSnackbar } from '@application/components/snackbar/renderSnackbar';
import { FeedbackContext } from '@application/contexts';
import { useTranslation } from 'react-i18next';
import { TenantInvitationsList } from '../tenant-invitations-list';

const useTenantInvitations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setFeedback } = useContext(FeedbackContext);

  const { t } = useTranslation();

  const {
    actions: { refetchTenantUsers },
    data: { count, tenantUsers },
    pagination: { currentPage, handlePaginationChange, handleRowsPerPageChange, resetPagination },
    sorting: { onSortChange, sortBy },
  } = useTenantInvitationsList({});

  const { inviteTenantUser, error, fetching: isInvitingUser } = useInviteTenantUserMutation();

  const methods = useForm<InviteTenantUserFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(INVITE_TENANT_USER_SCHEMA),
  });

  const { clearErrors, reset, setError } = methods;

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    reset({});
    setIsModalOpen(true);
  }, [reset]);

  const handleInviteUser = useCallback(
    async (fields: InviteTenantUserFormFields): Promise<void> => {
      clearErrors('email');

      const { data } = await inviteTenantUser({
        input: fields,
      });

      if (data?.inviteTenantUser?.user?.id) {
        setFeedback(<SuccessSnackbar title={t('tenant.inviteUser.success.title')} onClose={() => setFeedback(null)} />);
        setIsModalOpen(false);
        refetchTenantUsers();
      }
    },
    [clearErrors, inviteTenantUser, refetchTenantUsers, setFeedback, t],
  );

  const inviteUserErrors = useMemo(() => extractErrorCodes(error), [error]);

  const renderTenantInvitations = useCallback(
    () =>
      tenantUsers.length > 0 ? (
        <TenantInvitationsList
          data={tenantUsers}
          onOpenModal={handleOpenModal}
          onSortChange={onSortChange}
          pagination={{ count, currentPage, handlePaginationChange, handleRowsPerPageChange, resetPagination, hideOnSmallTotalCount: true }}
          sortBy={sortBy}
        />
      ) : (
        <EmptyState onOpenModal={handleOpenModal} />
      ),
    [tenantUsers, onSortChange, sortBy, count, currentPage, handlePaginationChange, handleOpenModal, handleRowsPerPageChange, resetPagination],
  );

  const Provider = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => <FormProvider {...methods}>{children}</FormProvider>,
    [methods],
  );

  useEffect(() => {
    if (!inviteUserErrors.length) {
      return;
    }

    if (inviteUserErrors.some(({ status }) => status === 409)) {
      setError('email', { type: 'onChange', message: 'modal.inviteUser.error.alreadyInTenant' });
    } else {
      setFeedback(<ErrorSnackbar onClose={() => setFeedback(null)} title={t('tenant.inviteUser.error.title')} />);
    }
  }, [inviteUserErrors, setError, setFeedback, t]);

  return {
    actions: { handleCloseModal, handleSubmit: methods.handleSubmit(handleInviteUser), handleOpenModal },
    renderTenantInvitations,
    form: { Provider },
    state: { isInvitingUser, isModalOpen },
  };
};

export default useTenantInvitations;
