import { Site } from '@domain/graphql.types';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SiteLookupContext from '../context/SiteLookupContext';
import ListCard from './ListCard';

type Props = {
  hasSearchResult: boolean;
  fetching: boolean;
  sites: readonly Site[];
  totalCount: number;
  trackedPortsCount: number;
};

const SiteLookupList = ({ hasSearchResult, fetching, sites, totalCount, trackedPortsCount }: Props) => {
  const selectedCardRef = useRef<HTMLDivElement | null>(null);

  const { selectedSite } = useContext(SiteLookupContext);

  const { t } = useTranslation();

  const { palette } = useTheme();

  useEffect(() => {
    if (!selectedSite || selectedSite.origin === 'list' || !selectedCardRef?.current) {
      return;
    }

    selectedCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [selectedSite]);

  const trackedPortsText = useMemo(
    () => (trackedPortsCount > 0 ? <>&nbsp;{t('siteLookup.list.trackedPorts', { count: trackedPortsCount })}</> : ''),
    [t, trackedPortsCount],
  );

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 'calc(100vh - (var(--top-bar-height) + var(--search-bar-height)))',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        paddingBottom: 6,
      }}
    >
      {hasSearchResult && !fetching && (
        <>
          <Typography
            fontSize={18}
            fontWeight={600}
            paddingX={1.5}
            marginBlockStart={3}
            marginBlockEnd={2}
            sx={{ '&>span': { color: palette.text.secondary } }}
          >
            {t('siteLookup.list.sitesFound', { count: totalCount })}
            <span>{trackedPortsText}</span>
          </Typography>

          {sites.map((site, index) => (
            <ListCard ref={site.id === selectedSite?.id ? selectedCardRef : null} key={site.id} isLast={index === sites.length - 1} site={site} />
          ))}

          {totalCount > 0 && (
            <Typography fontSize={14} fontWeight={500} paddingX={1.5} marginBlockStart={5} textAlign="center">
              {t('siteLookup.list.endReached')}
            </Typography>
          )}
        </>
      )}

      {fetching && <CircularProgress sx={{ alignSelf: 'center', marginTop: 32 }} />}
    </Stack>
  );
};

export default SiteLookupList;
