import { dispatchLogout } from '@domain/authentication';
import { Logout } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LogoutMenuItem = () => {
  const { t } = useTranslation();

  return (
    <MenuItem dense onClick={dispatchLogout}>
      <ListItemIcon color="primary">
        <Logout color="action" fontSize="small" sx={{ opacity: 0.75 }} />
      </ListItemIcon>
      {t('navigation.logout')}
    </MenuItem>
  );
};

export default LogoutMenuItem;
