import { useAuthentication } from '@domain/authentication';
import { client } from '@infrastructure/graphql';
import React, { useMemo } from 'react';
import { Provider } from 'urql';

type GqlProviderProps = {
  children: React.ReactNode;
};

const GqlProvider = ({ children }: GqlProviderProps) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuthentication();

  const value = useMemo(
    () => ({
      getAccessTokenSilently,
      isAuthenticated,
    }),
    [getAccessTokenSilently, isAuthenticated],
  );

  return <Provider value={client(value)}>{children}</Provider>;
};

export default GqlProvider;
