import { ReportAggregationMenu } from '@application/components';
import { ReportAggregation } from '@domain/graphql.types';
import { Download, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onDownloadReport: (aggregation: ReportAggregation) => void;
  isDownloadReportDisabled: boolean;
};

const DownloadReportMenu = ({ onDownloadReport, isDownloadReportDisabled }: Props) => {
  const [downloadReportMenuAnchor, setDownloadReportMenuAnchor] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleDownloadReportMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setDownloadReportMenuAnchor(event.currentTarget);
  }, []);

  const handleDownloadReportMenuClose = useCallback(() => {
    setDownloadReportMenuAnchor(null);
  }, []);

  const handleDownloadAndClose = useCallback(
    (aggregation: ReportAggregation) => () => {
      onDownloadReport(aggregation);
      handleDownloadReportMenuClose();
    },
    [onDownloadReport, handleDownloadReportMenuClose],
  );

  return (
    <Box>
      <Button
        disabled={isDownloadReportDisabled}
        onClick={handleDownloadReportMenuClick}
        endIcon={<KeyboardArrowDownOutlined />}
        startIcon={<Download />}
        variant="contained"
      >
        {t('siteLookup.downloadReport')}
      </Button>

      <ReportAggregationMenu
        anchorEl={downloadReportMenuAnchor}
        onAggregationSelected={handleDownloadAndClose}
        onClose={handleDownloadReportMenuClose}
        open={!!downloadReportMenuAnchor}
      />
    </Box>
  );
};

export default DownloadReportMenu;
