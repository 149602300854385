import { ComingSoonPin, CurrentPositionPin, LegendIcon, SelectedPin, TrackedPin, UntrackedPin } from '@application/assets';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const menuItemStyle = {
  cursor: 'unset',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const LegendMapControl = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const { t } = useTranslation();

  const { borderRadius, palette, zIndex } = useTheme();

  const handleLegendOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleLegendClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const open = !!anchorElement;

  return (
    <Box sx={{ position: 'absolute', top: '24px', right: '24px', zIndex: zIndex.fab, boxShadow: 1, borderRadius: borderRadius.sm }}>
      <Button
        startIcon={<LegendIcon />}
        size="small"
        sx={{
          overflow: 'hidden',
          width: open ? '100%' : '40px',
          minWidth: 40,
          minHeight: 40,
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: palette.common.white,

          '&:hover': {
            width: '100%',
          },
        }}
        color="inherit"
        variant="contained"
        onClick={handleLegendOpen}
        endIcon={open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      >
        {t('siteLookup.legend.title')}
      </Button>

      <Popover
        sx={{
          marginTop: 0.5,
          '& .MuiPopover-paper': {
            borderRadius: borderRadius.md,
            boxShadow: 1,
          },
        }}
        anchorEl={anchorElement}
        onClose={handleLegendClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem sx={menuItemStyle}>
            <ListItemIcon>
              <CurrentPositionPin height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('siteLookup.legend.currentAddress')}</ListItemText>
          </MenuItem>

          <Divider sx={{ marginX: 2 }} />

          <MenuItem sx={menuItemStyle}>
            <ListItemText>{t('siteLookup.legend.chargingStation')}</ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyle}>
            <ListItemIcon>
              <TrackedPin height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('siteLookup.legend.tracked')}</ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyle}>
            <ListItemIcon>
              <UntrackedPin height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('siteLookup.legend.untracked')}</ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyle}>
            <ListItemIcon>
              <ComingSoonPin height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('siteLookup.legend.comingSoon')}</ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyle}>
            <ListItemIcon>
              <SelectedPin height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('siteLookup.legend.selected')}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
};

export default LegendMapControl;
