import { Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';

type AuthenticationProviderProps = PropsWithChildren<unknown>;

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => (
  <Auth0Provider
    domain={`${import.meta.env.VITE_REACT_APP_AUTH_DOMAIN}`}
    clientId={`${import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID}`}
    useRefreshTokens
    authorizationParams={{
      audience: `${import.meta.env.VITE_REACT_APP_AUTH_AUDIENCE}`,
      redirect_uri: window.location.origin,
    }}
    cacheLocation="localstorage"
  >
    {children}
  </Auth0Provider>
);

export default AuthenticationProvider;
