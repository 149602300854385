import { useAuth0 } from '@auth0/auth0-react';
import { Site } from '@domain/graphql.types';
import { SitesRequest } from '@infrastructure/http';
import { useEffect, useState } from 'react';

const useSitesRequest = () => {
  const [data, setData] = useState<Site[] | undefined>();

  const { getAccessTokenSilently } = useAuth0();

  // biome-ignore lint/correctness/useExhaustiveDependencies: on mount
  useEffect(() => {
    const execute = async () => {
      const token = await getAccessTokenSilently();

      const result = await fetch(SitesRequest(token));

      if (result.ok) {
        const data = (await result.json()) as Site[];

        setData(data);
      }
    };

    execute();
  }, []);

  return {
    data,
  };
};

export default useSitesRequest;
