import { CaretIcon } from '@application/assets';
import { DefaultBulkReportRadius } from '@domain/index';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadiusFilter from '../site-lookup/filters/RadiusFilter';
import BulkDropzone from './components/BulkDropzone';
import BulkFile from './components/BulkFile';
import BulkTemplate from './components/BulkTemplate';
import GenerateReportMenu from './components/GenerateReportMenu';
import useBulkSearch, { FileState } from './hooks/useBulkSearch';
import AddressesList from './list/AddressesList';
import BulkMap from './map/BulkMap';

const BulkSearch = () => {
  const [expanded, setExpanded] = useState(true);
  const [height, setHeight] = useState<number | undefined>(0);

  const accordionRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const { zIndex, palette } = useTheme();

  const {
    actions: { handleGenerateBulkReport, toggleIsMapExtended, uploadFile, updateFilter },
    list,
    state: { dropError, file, fileState, filters, generatingReport, isMapExtended, showList, validAddresses, validatingAddresses, validationError },
  } = useBulkSearch();

  const handleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  useEffect(() => {
    if (!showList) return;

    setExpanded(false);
  }, [showList]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!accordionRef.current) {
      return;
    }

    // get the height of the accordion section programmatically
    // in order to apply to the preview title section top position
    const resizeObserver = new ResizeObserver(() => {
      if (accordionRef.current?.offsetHeight !== height) {
        setHeight(accordionRef.current?.offsetHeight);
      }
    });

    resizeObserver.observe(accordionRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [accordionRef.current?.clientHeight]);

  return (
    <Box display="flex" height="100%" width="100%">
      <Box
        sx={{
          zIndex: zIndex.mobileStepper,
          boxShadow: '0px 0px 3px rgb(0 0 0 / 12%)',
        }}
      >
        <Stack
          display={isMapExtended ? 'none' : 'block'}
          minWidth={560}
          width={560}
          sx={{
            height: '100%',
            paddingX: 3,
            minHeight: 'calc(100vh - var(--top-bar-height) - 1px)',
          }}
        >
          <Accordion
            ref={accordionRef}
            defaultExpanded
            sx={{
              position: 'sticky',
              top: 'calc(var(--top-bar-height) + 1px)',
              zIndex: 1,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
            elevation={0}
            disableGutters
            expanded={expanded}
            onChange={handleExpanded}
          >
            <AccordionSummary expandIcon={<CaretIcon />} sx={{ padding: 0, margin: 0, '.MuiAccordionSummary-content': { marginY: 3 } }}>
              <Typography component="h2" variant="h5" fontWeight={500}>
                {t('bulkSearch.importCsvFile')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, paddingBottom: 3 }}>
              <Box display="flex" flexDirection="column" gap={3}>
                <BulkDropzone uploadFile={uploadFile} dropError={dropError} />

                <BulkTemplate />

                {file && <BulkFile file={file} fileState={fileState} validatingAddresses={validatingAddresses} validationError={validationError} />}
              </Box>
            </AccordionDetails>
          </Accordion>

          {showList && (
            <Stack gap={1}>
              <Box
                style={{ top: `calc(var(--top-bar-height) + ${height}px + 0.8px)` }}
                sx={{
                  position: 'sticky',
                  marginX: -3,
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(to bottom, ${palette.common.white} 53%, ${alpha(palette.common.white, 0.86)} 85%)`,
                    backdropFilter: 'blur(2px)',
                  }}
                />

                <Typography
                  component="h2"
                  variant="h5"
                  fontWeight={500}
                  sx={{
                    position: 'relative',
                    zIndex: '1',
                    marginY: 3,
                    paddingX: 3,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '36ch',
                  }}
                >
                  {t('bulkSearch.previewFile', { filename: file.name.split('.')[0] })}
                </Typography>
              </Box>

              <AddressesList onFilterChange={updateFilter} filters={filters} {...list} />
            </Stack>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          position: 'sticky',
          top: 'calc(var(--top-bar-height) + 1px)',
          height: `calc(100vh - var(--top-bar-height) - 1px)`,
          width: '100%',
        }}
      >
        {fileState !== FileState.IDLE && (
          <Box
            display="flex"
            paddingY={2}
            paddingX={3}
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              inset: 0,
              zIndex: 1,
              width: '100%',
              height: '73px',
              borderBottom: '1px solid',
              borderColor: 'divider',
              backgroundColor: palette.common.white,
            }}
          >
            <RadiusFilter readonly value={DefaultBulkReportRadius} />

            <GenerateReportMenu generatingReport={generatingReport} onGenerateReport={handleGenerateBulkReport} />
          </Box>
        )}

        <BulkMap addresses={validAddresses} fileState={fileState} isMapExtended={isMapExtended} toggleIsMapExtended={toggleIsMapExtended} />
      </Box>
    </Box>
  );
};

export default BulkSearch;
