import { isValidPhoneNumber } from '@application/utils/phone-utils';
import * as yup from 'yup';

export const ONBOARDING_SCHEMA = yup.object({
  firstName: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(128, 'validations.textInput.maxLength')
    .required('validations.required.firstName'),
  lastName: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(128, 'validations.textInput.maxLength')
    .required('validations.required.lastName'),
  position: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(128, 'validations.textInput.maxLength')
    .required('validations.required.position'),
  tenantName: yup
    .string()
    .transform((_, val) => val || undefined)
    .trim()
    .min(3, 'validations.textInput.minLength')
    .max(128, 'validations.textInput.maxLength')
    .required('validations.required.tenantName'),
  address: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(256, 'validations.textInput.maxLength')
    .required('validations.required.tenantAddress'),
  phone: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(36, 'validations.textInput.maxLength')
    .required('validations.required.phone')
    .test('validPhoneNumber', 'validations.phone.invalidFormat', (value) => isValidPhoneNumber(value)),
  otpCode: yup
    .string()
    .transform((_, val) => val || undefined)
    .required('validations.required.otpCode'),
  areTermsAndConditionsAccepted: yup
    .boolean()
    .default(true)
    .oneOf([true], 'validations.termsAndConditions.mustAccept')
    .required('validations.termsAndConditions.mustAccept'),
});

export type OnboardingSchemaFormFields = yup.InferType<typeof ONBOARDING_SCHEMA>;
