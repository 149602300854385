import { CheckCircleIcon, CsvIcon, WarningCircleIcon } from '@application/assets';
import { Box, LinearProgress, Stack, Typography, alpha } from '@mui/material';
import { filesize } from 'filesize';
import { useMemo } from 'react';
import { FileState } from '../hooks/useBulkSearch';

type Props = {
  file: File;
  fileState: FileState;
  validatingAddresses: boolean;
  validationError?: string | null;
};

const BulkFile = ({ file, fileState, validatingAddresses, validationError }: Props) => {
  const fileCardColor = useMemo(() => {
    if (validationError) {
      return alpha('#B2535B', 0.1);
    }

    if (fileState === FileState.SUCCEEDED) {
      return alpha('#5A835D', 0.1);
    }

    return '#F5F5F5';
  }, [fileState, validationError]);

  return (
    <Stack gap={1}>
      <Stack sx={{ backgroundColor: fileCardColor, borderRadius: 2 }} gap={2} padding={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={1}>
            <CsvIcon />

            <Stack justifyContent="center">
              <Typography variant="body2" fontWeight={500}>
                {file.name}
              </Typography>

              <Typography color="text.secondary" variant="body2">
                {filesize(file.size)}
              </Typography>
            </Stack>
          </Box>

          {validationError && <WarningCircleIcon sx={{ color: 'error.dark' }} />}

          {fileState === FileState.SUCCEEDED && <CheckCircleIcon sx={{ color: 'success.dark' }} />}
        </Box>

        {validatingAddresses && <LinearProgress />}
      </Stack>

      {validationError && (
        <Typography color="error" variant="body2">
          {validationError}
        </Typography>
      )}
    </Stack>
  );
};

export default BulkFile;
