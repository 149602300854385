import { ComingSoonPin, SelectedPin, TrackedPin, UntrackedPin } from '@application/assets';
import { SiteStatus, TrackingState } from '@domain/graphql.types';

type Props = {
  height?: number;
  isSelected?: boolean;
  state: TrackingState;
  status: SiteStatus;
  width?: number;
};

const MapPin = ({ height, isSelected, state, status, width }: Props) => {
  if (isSelected) {
    return <SelectedPin height={height} width={width} />;
  }

  if (status === SiteStatus.ComingSoon) {
    return <ComingSoonPin height={height} width={width} />;
  }

  switch (state) {
    case TrackingState.Tracked:
      return <TrackedPin height={height} width={width} />;
    case TrackingState.NotTracked:
      return <UntrackedPin height={height} width={width} />;
  }
};

export default MapPin;
