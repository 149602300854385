import { AlertTitle, Box, Alert as MUIAlert, Snackbar as MUISnackbar } from '@mui/material';

export type SnackbarProps = {
  title: string;
  message?: string;
  open: boolean;
  severity?: 'success' | 'info' | 'warning' | 'error';
  variant?: 'standard' | 'filled' | 'outlined';
  onClose?: () => void;
};

const Snackbar = ({ open, severity = 'info', title, message, variant = 'standard', onClose }: SnackbarProps) => {
  const handleOnClose = (_: React.SyntheticEvent | Event) => {
    if (onClose) onClose();
  };

  return (
    <MUISnackbar
      open={open}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={['success', 'info'].includes(severity) ? 5000 : undefined}
      onClose={handleOnClose}
      sx={{ maxWidth: 460 }}
    >
      <MUIAlert severity={severity} variant={variant} onClose={handleOnClose} sx={{ borderRadius: 3 }} elevation={2}>
        <AlertTitle sx={{ margin: 0 }}>{title}</AlertTitle>
        {message && <Box sx={{ marginBlockStart: 1 }}>{message}</Box>}
      </MUIAlert>
    </MUISnackbar>
  );
};

export default Snackbar;
