import { SubscriptionPlan } from '@domain/graphql.types';
import PlanGuard from './PlanGuard';

type PayingCustomerGuardProps = React.PropsWithChildren<{
  noRedirect?: boolean;
}>;

const PayingCustomerGuard = ({ children, noRedirect }: PayingCustomerGuardProps) => {
  return (
    <PlanGuard authorizedPlans={[SubscriptionPlan.Business, SubscriptionPlan.Premium, SubscriptionPlan.Starter]} noRedirect={noRedirect}>
      {children}
    </PlanGuard>
  );
};

export default PayingCustomerGuard;
