import { createTheme, Theme } from '@mui/material';

import { palette } from './palette';
import { typography } from './typography';
import { borderRadius } from './border-radius';
import { components } from './components';
import { zIndex } from './z-index';
import { shadows } from './shadows';

declare module '@mui/material/styles' {
  interface Theme {
    borderRadius: {
      default: string;
      none: string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      full: string;
      pill: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    borderRadius?: {
      default?: string;
      none?: string;
      xs?: string;
      sm?: string;
      md?: string;
      lg?: string;
      xl?: string;
      pill?: string;
      full?: string;
    };
  }
}

export const theme: Theme = createTheme({
  palette,
  borderRadius,
  typography,
  components,
  shadows,
  zIndex,
});
