import { gql } from '@urql/core';
import { UserWithDefaultTenant } from '../fragments';

const MutationCompleteOnboarding = gql`
  ${UserWithDefaultTenant}

  mutation CompleteOnboarding($input: CompleteOnboardingRequest!) {
    completeOnboarding(input: $input) {
      user {
        ...UserWithDefaultTenantFragment
      }
    }
  }
`;

export default MutationCompleteOnboarding;
