import { SvgIcon, SxProps, Theme } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  size?: 'inherit' | 'large' | 'medium' | 'small';
};

const WarningCircleIcon = ({ size = 'small', sx }: Props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fontSize={size} sx={sx}>
    <g>
      <path
        d="M12 23.142857142857142c6.154114285714286 0 11.142857142857142 -4.9887428571428565 11.142857142857142 -11.142857142857142C23.142857142857142 5.845971428571428 18.154114285714286 0.8571428571428571 12 0.8571428571428571 5.845971428571428 0.8571428571428571 0.8571428571428571 5.845971428571428 0.8571428571428571 12c0 6.154114285714286 4.988828571428571 11.142857142857142 11.142857142857142 11.142857142857142Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M12 6.857142857142857v5.571428571428571" strokeWidth="1.25" fill="none" />
      <g>
        <path
          d="M12 17.142857142857142c-0.23669142857142855 0 -0.42857142857142855 -0.19188 -0.42857142857142855 -0.42857142857142855s0.19188 -0.42857142857142855 0.42857142857142855 -0.42857142857142855"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.25"
        />
        <path
          d="M12 17.142857142857142c0.23669142857142855 0 0.42857142857142855 -0.19188 0.42857142857142855 -0.42857142857142855S12.236691428571428 16.285714285714285 12 16.285714285714285"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.25"
        />
      </g>
    </g>
  </SvgIcon>
);

export default WarningCircleIcon;
