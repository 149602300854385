import UserContext from '@application/contexts/UserContext';
import { Routes } from '@application/routes';
import { UserRole } from '@domain/graphql.types';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

type RoleGuardProps = React.PropsWithChildren<{
  authorizedRoles?: UserRole[] | null;
  noRedirect?: boolean;
}>;

const RoleGuard = ({ children, authorizedRoles, noRedirect }: RoleGuardProps) => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate();

  const isAuthorized = useMemo(() => authorizedRoles && role && authorizedRoles.includes(role), [role, authorizedRoles]);

  useEffect(() => {
    if (!isAuthorized && !noRedirect) {
      navigate(Routes.SITE_LOOKUP, { replace: true });
    }
  }, [isAuthorized, noRedirect, navigate]);

  return isAuthorized && (children as any);
};

export default RoleGuard;
