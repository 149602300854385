import { gql } from '@urql/core';
import { getPage } from '../fragments';
import ReportFragment from '../fragments/report.fragment';

const Reports = gql`
  ${ReportFragment}

  query Reports(
    $sortBy: [ReportsSortDirective!],
    $filterBy: ReportsFilters,
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    reports(
      sortBy: $sortBy,
      filterBy: $filterBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['ReportFragment'])}
    }
  }
`;

export default Reports;
