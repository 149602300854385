import { MutationCompleteOnboarding } from '@infrastructure/graphql';
import { useMutation } from 'urql';
import { Mutation, MutationCompleteOnboardingArgs } from '../graphql.types';

const useCompleteOnboarding = () => {
  const [{ data, fetching, error }, completeOnboarding] = useMutation<Pick<Mutation, 'completeOnboarding'>, MutationCompleteOnboardingArgs>(
    MutationCompleteOnboarding,
  );

  return {
    completeOnboarding,
    data,
    fetching,
    error,
  };
};

export default useCompleteOnboarding;
