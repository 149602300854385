type Props = {
  height?: number;
  width?: number;
};

const SelectedPin = ({ height = 40, width = 40 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="SelectedPin">
      <path
        d="M20 3.33331C13.5567 3.33331 8.33334 8.55665 8.33334 15C8.33334 23.3333 20 36.6666 20 36.6666C20 36.6666 31.6667 23.3333 31.6667 15C31.6667 8.55665 26.4433 3.33331 20 3.33331Z"
        fill="#4CDE87"
      />
      <path d="M20.2564 8.33331L16.6667 16.2121H19.7436V21.6666L23.3333 13.7879H20.2564V8.33331Z" fill="white" />
    </svg>
  );
};

export default SelectedPin;
