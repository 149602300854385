import LinkComponent from '@application/components/link-component/LinkComponent';
import { Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router';

type TabData = {
  to: string;
  label: string;
};

type Props = {
  tabs: TabData[];
};

const NavigationTabs = ({ tabs }: Props) => {
  const { pathname } = useLocation();

  return (
    <Tabs value={pathname}>
      {tabs.map((tab) => (
        <Tab key={tab.to} label={tab.label} value={tab.to} href={tab.to} LinkComponent={LinkComponent} />
      ))}
    </Tabs>
  );
};

export default NavigationTabs;
