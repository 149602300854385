type Props = {
  height?: number;
  width?: number;
  full?: boolean;
};

const LogoParen = ({ height, width, full }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 723 209" fill="none" height={height} width={width} role="img" aria-label="Paren logo">
      <mask id="mask0_3_115" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="108" height="157">
        <path
          d="M107.49 20.95C87.21 9.24 61.84 23.88 61.84 47.31V63.37C58.65 65.67 55.77 68.39 53.29 71.48C47.41 78.79 43.79 88.14 43.79 98.76V148.15C43.79 149.74 44.02 151.25 44.46 152.66C24.42 163.1 0.100039 148.64 0.0400391 125.73V56.41C0.0400391 13.05 46.98 -14.04 84.53 7.63L105.65 19.83C106.28 20.19 106.89 20.56 107.49 20.95Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_3_115)">
        <g filter="url(#filter0_f_3_115)">
          <path
            d="M43 88.5C18.2 57.7 14 43.4274 -6.5 50.9274L-29 61.4274L-20.5 100.927L-23 144.427L-20.5 168.927L-1 188.427L14.5 192.427L36.5 185.427L60.5 157C76 150.927 67.8 119.3 43 88.5Z"
            fill="#1245F7"
          />
        </g>
        <g filter="url(#filter1_f_3_115)">
          <path
            d="M-22 72.9273C23.0001 116.427 31.0001 100 49.5 79.5C47.3147 75.0566 51.3953 65.4457 49.5 60C40.9877 35.5417 47.1947 5.82933 84 -2.00003C72.1667 -9.83337 41.4001 -17.6727 11.0001 3.9273C-26.9999 30.9273 -22.5 59.9273 -22 72.9273Z"
            fill="url(#paint0_linear_3_115)"
          />
        </g>
      </g>
      <mask id="mask1_3_115" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="105" width="160" height="104">
        <path
          d="M144.43 166.35L144.32 166.41L84.48 200.96C46.93 222.64 0 195.54 0 152.18V127.8C0 127.11 0.00999996 126.42 0.04 125.73C0.1 148.64 24.42 163.1 44.46 152.66C47.19 161.59 57.83 166.35 66.57 161.3L77.15 155.19L109.35 136.61C121.62 129.52 128.73 117.96 130.66 105.69L144.43 113.64C164.72 125.35 164.72 154.64 144.43 166.35Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1_3_115)">
        <g filter="url(#filter2_f_3_115)">
          <path
            d="M85.7039 146.235C65.5679 180.267 53.6775 189.21 67.8956 205.773L85.6248 223.156L119.628 201.333L161.238 188.406L183.302 177.466L194.717 152.362L193.023 136.444L178.746 118.301L144.5 83.5C133.373 71.1175 105.84 112.202 85.7039 146.235Z"
            fill="#1245F7"
          />
        </g>
        <g filter="url(#filter3_f_3_115)">
          <path
            d="M93.9361 212.565C118.874 155.16 100.684 153.435 74.9949 143.307C71.6012 146.913 61.1695 146.465 56.7355 150.151C36.821 166.707 6.74945 194.216 -13.5 162.5C-16.6816 176.33 -13.1526 184.998 17.7432 205.883C56.363 231.988 81.9386 217.596 93.9361 212.565Z"
            fill="url(#paint1_linear_3_115)"
          />
        </g>
      </g>
      <mask id="mask2_3_115" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="61" y="16" width="135" height="151">
        <path
          d="M167.45 153.12L146.33 165.31C145.7 165.67 145.07 166.02 144.43 166.35C164.72 154.64 164.72 125.35 144.43 113.64L130.66 105.69C131.53 100.11 131.34 94.39 130.08 88.89C127.55 77.73 120.63 67.43 109.35 60.91C93.2801 51.64 75.0101 53.87 61.8401 63.37V47.31C61.8401 23.88 87.2101 9.24002 107.49 20.95L107.61 21.02L167.45 55.56C204.99 77.24 204.99 131.44 167.45 153.12Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask2_3_115)">
        <g filter="url(#filter4_f_3_115)">
          <path
            d="M112.917 79.0056C152.451 79.8616 166.076 85.8378 173.537 65.3235L179.99 41.3474L144.294 22.418L112.621 -7.50328L92.2653 -21.3653L64.7897 -19.0008L51.7492 -9.71675L42.9399 11.6233L42.9399 57C37.6062 72.7695 73.3829 78.1496 112.917 79.0056Z"
            fill="#1245F7"
          />
        </g>
        <g filter="url(#filter5_f_3_115)">
          <path
            d="M166.684 39.2988C104.426 45.7214 111.844 62.4195 115.618 89.7738C120.425 90.9628 118.613 96.9438 124 99C145.5 89.7738 194.993 129 166.684 164.5C180.297 160.49 195.183 146.143 198.23 108.976C202.039 62.5159 176.953 47.2866 166.684 39.2988Z"
            fill="url(#paint2_linear_3_115)"
          />
        </g>
      </g>

      {full && (
        <>
          <path
            d="M235.46 87.47C235.46 59.1 254.85 40.94 281.81 40.94C308.77 40.94 328.86 59.97 328.86 87.29C328.86 116.19 307.36 132.58 284.62 132.58C270.52 132.58 258.54 126.24 252.19 114.96V167.48H235.45V87.47H235.46ZM311.95 86.76C311.95 69.14 299.97 56.45 281.99 56.45C264.01 56.45 252.21 69.14 252.21 86.76C252.21 104.38 264.19 117.07 281.99 117.07C299.79 117.07 311.95 104.38 311.95 86.76Z"
            fill="black"
          />
          <path
            d="M342.61 87.47C342.61 60.15 362 40.94 389.66 40.94C417.32 40.94 436.01 59.62 436.01 87.47V130.3H420.68V111.27C414.86 125.37 402 132.59 386.84 132.59C364.11 132.59 342.6 116.2 342.6 87.47H342.61ZM419.45 86.76C419.45 69.14 407.47 56.45 389.49 56.45C371.51 56.45 359.53 69.14 359.53 86.76C359.53 104.38 371.51 117.07 389.49 117.07C407.47 117.07 419.45 104.38 419.45 86.76Z"
            fill="black"
          />
          <path
            d="M449.76 79.01C449.76 53.28 464.92 40.94 487.3 40.94C509.68 40.94 524.66 53.28 524.66 79.18V80.06H508.09V79C508.09 62.96 499.63 56.44 487.29 56.44C474.95 56.44 466.49 62.96 466.49 79V130.28H449.75V79L449.76 79.01Z"
            fill="black"
          />
          <path
            d="M533.82 86.76C533.82 60.32 553.21 40.94 580.7 40.94C608.19 40.94 627.23 60.33 627.23 86.76V92.93H551.45C553.92 108.44 565.55 117.07 580.71 117.07C591.99 117.07 599.92 113.55 605.03 105.97H623.53C616.83 122.18 600.97 132.58 580.7 132.58C553.21 132.58 533.82 113.19 533.82 86.76ZM609.6 77.95C606.25 64.03 594.8 56.45 580.7 56.45C566.6 56.45 555.32 64.2 551.97 77.95H609.6Z"
            fill="black"
          />
          <path
            d="M636.36 85C636.36 55.92 653.63 40.94 679.36 40.94C705.09 40.94 722.36 55.74 722.36 85V130.29H705.62V85C705.62 66.14 695.22 56.45 679.36 56.45C663.5 56.45 653.1 66.14 653.1 85V130.29H636.36V85Z"
            fill="black"
          />
        </>
      )}

      <defs>
        <filter id="filter0_f_3_115" x="-59" y="18.9621" width="157.371" height="203.465" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <filter id="filter1_f_3_115" x="-52.4156" y="-40.0854" width="166.416" height="170.438" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <filter id="filter2_f_3_115" x="31.6654" y="51.2446" width="193.052" height="201.911" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <filter id="filter3_f_3_115" x="-44.446" y="113.307" width="180.264" height="137.762" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <filter id="filter4_f_3_115" x="12.4102" y="-51.3654" width="197.58" height="161.834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <filter id="filter5_f_3_115" x="83.2003" y="9.29883" width="145.42" height="185.201" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_3_115" />
        </filter>
        <linearGradient id="paint0_linear_3_115" x1="65.5" y1="46.9274" x2="19" y2="111.927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A8FE" />
          <stop offset="0.398944" stopColor="#02A8FE" />
          <stop offset="0.956666" stopColor="#1245F7" />
        </linearGradient>
        <linearGradient id="paint1_linear_3_115" x1="38.8779" y1="139.757" x2="116.064" y2="160.484" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A8FE" />
          <stop offset="0.398944" stopColor="#02A8FE" />
          <stop offset="0.956666" stopColor="#1245F7" />
        </linearGradient>
        <linearGradient id="paint2_linear_3_115" x1="130.238" y1="122.989" x2="110.444" y2="45.5582" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A8FE" />
          <stop offset="0.398944" stopColor="#02A8FE" />
          <stop offset="0.956666" stopColor="#1245F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoParen;
