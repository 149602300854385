import { Shadows } from '@mui/material';

export const shadows = [
  'none',
  '0 2px 4px rgba(0,0,0,0.18)',
  '0 6px 16px rgba(0,0,0,0.12)',
  '0 6px 20px rgba(0,0,0,0.2)',
  '0 8px 28px rgba(0,0,0,0.28)',
  ...Array(20).fill('none'),
] as Shadows;
