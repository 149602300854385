import { theme } from '@application/styles/theme';
import { Box, CssBaseline, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material';
import { ReactNode } from 'react';

const ThemeProvider = ({ children }: { children: ReactNode }): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <MUIThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        {children}
      </Box>
    </MUIThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
