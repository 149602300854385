import { createContext } from 'react';

export type SelectedSite = {
  id: string;
  origin: 'list' | 'map';
};

export type SearchLimit = {
  exceeded: boolean;
  count: number;
};

type SiteLookupContextProps = {
  isMapExtended: boolean;
  toggleIsMapExtended: () => void;
  setIsMapExtended: (value: boolean) => void;
  selectedSite: SelectedSite | null;
  setSelectedSite: (value: SelectedSite | null) => void;
  searchLimit: SearchLimit | null;
  setSearchLimit: (value: SearchLimit | null) => void;
};

const SiteLookupContext = createContext<SiteLookupContextProps>({
  isMapExtended: false,
  toggleIsMapExtended: () => {},
  setIsMapExtended: () => {},
  selectedSite: null,
  setSelectedSite: () => {},
  searchLimit: null,
  setSearchLimit: () => {},
});

export default SiteLookupContext;
