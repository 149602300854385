import { gql } from '@urql/core';

const DownloadReport = gql`
  mutation DownloadReport(
    $input: DownloadReportRequest!,
  ) {
    downloadReport(
      input: $input,
    ) {
      link
    }
  }
`;

export default DownloadReport;
