import { NavigationTabs } from '@application/components';
import { UserContext } from '@application/contexts';
import { Routes } from '@application/routes';
import { UserRole } from '@domain/graphql.types';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

const Tenant = () => {
  const { t } = useTranslation();

  const { role } = useContext(UserContext);

  const tabs = useMemo(
    () =>
      [
        {
          label: t('navigationTabs.tenantUsers'),
          to: Routes.TENANT_USERS,
        },
        {
          label: t('navigationTabs.tenantInvitations'),
          to: Routes.TENANT_INVITATIONS,
        },
        {
          label: t('navigationTabs.tenantSubscription'),
          to: Routes.TENANT_SUBSCRIPTION,
          roles: [UserRole.Owner],
        },
      ].filter(({ roles }) => (roles ? role && roles.includes(role) : true)),
    [role, t],
  );

  return (
    /* Since the maxWidth props only takes a defined breakpoint or a boolean,
       we must redefine it in order to use a custom value  */
    <Container maxWidth={false} sx={{ maxWidth: 1380 }}>
      <Stack gap={3} margin={3}>
        <Typography variant="h1">{t('tenant.heading')}</Typography>

        <NavigationTabs tabs={tabs} />

        <Box>
          <Outlet />
        </Box>
      </Stack>
    </Container>
  );
};

export default Tenant;
