import { useCallback, useState } from 'react';

type Props = {
  initialStep?: number;
};

const useStepper = ({ initialStep }: Props) => {
  const [activeStep, setActiveStep] = useState(initialStep || 0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return {
    activeStep,
    handleBack,
    handleNext,
  };
};

export default useStepper;
