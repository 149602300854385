import { gql } from '@urql/core';

const QueryAvailableTenantSlug = gql`
  query AvailableTenantSlug {
    availableTenantSlug {
      slug
    }
  }
`;

export default QueryAvailableTenantSlug;
