import { Mutation, MutationGenerateBulkReportArgs } from '@domain/graphql.types';
import { MutationGenerateBulkReport } from '@infrastructure/graphql';
import { useMutation } from 'urql';

export const DefaultBulkReportRadius = 10;

const useGenerateBulkReportMutation = () => {
  const [{ data, fetching, error }, generateBulkReport] = useMutation<Pick<Mutation, 'generateBulkReport'>, MutationGenerateBulkReportArgs>(
    MutationGenerateBulkReport,
  );

  return {
    data,
    generatingReport: fetching,
    error,
    generateBulkReport,
  };
};

export default useGenerateBulkReportMutation;
