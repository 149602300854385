import { PageLoader } from '@application/components';
import { UserContext } from '@application/contexts';
import { useContext, useEffect } from 'react';

type UserGuardProps = {
  children: React.ReactNode;
};

const UserGuard = ({ children }: UserGuardProps) => {
  const { error, initializeUser, initializationCompleted } = useContext(UserContext);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  if (!initializationCompleted) {
    return <PageLoader />;
  }

  if (error) {
    throw error;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default UserGuard;
