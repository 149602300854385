import { Box, Dialog, DialogTitle, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
};

const DownloadReportDialog = ({ open }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} sx={{ '& .MuiPaper-root': { borderRadius: 3 } }}>
      <Box display="flex" flexDirection="column" paddingTop={2} paddingBottom={4} paddingX={3} gap={4} minWidth={320}>
        <DialogTitle sx={{ p: 0 }}>{t('siteLookup.preparingReport')}</DialogTitle>

        <LinearProgress />
      </Box>
    </Dialog>
  );
};

export default DownloadReportDialog;
