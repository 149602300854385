import { gql } from '@urql/core';

const RegenerateBulkReport = gql`
  mutation RegenerateBulkReport(
    $input: RegenerateReportRequest!,
  ) {
    regenerateBulkReport(
      input: $input,
    ) {
      report {
        id
      }
    }
  }
`;

export default RegenerateBulkReport;
