import { SvgIcon, SxProps, Theme } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  size?: 'inherit' | 'large' | 'medium' | 'small';
};

const FileSparkIcon = ({ size = 'small', sx }: Props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.625 -0.625 24 24" fontSize={size} sx={sx}>
    <g>
      <path
        d="M4.0625 8.9375v-6.5c0 -0.43098250000000005 0.17121 -0.84430125 0.47594624999999996 -1.149048875C4.84319875 0.9837051250000001 5.2565175 0.8125 5.6875 0.8125H16.25L21.9375 6.5v13.8125c0 0.43095 -0.17127499999999998 0.8443499999999999 -0.4759625 1.1490375s-0.7180875 0.4759625 -1.1490375 0.4759625h-9.75"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="currentColor"
      />
      <path
        d="M1.233643125 17.3684875c-0.561523625 -0.0992875 -0.561523625 -0.9189375 -0.000001625 -1.0182250000000002C3.26794 15.990324999999999 4.8859525 14.414708749999999 5.3274975 12.3636175l0.033848750000000004 -0.15721875000000002c0.12148499999999998 -0.56433 0.91169 -0.5678399999999999 1.0380175 -0.004615l0.04109625 0.18321875c0.4578925 2.0414225 2.07634375 3.60371375 4.10499375 3.9626599999999996 0.564395 0.099775 0.564395 0.9236500000000001 0 1.023425 -2.02865 0.3589625 -3.64710125 1.9212375 -4.10499375 3.9627250000000003l-0.04109625 0.18313749999999998c-0.1263275 0.5632250000000001 -0.9165325 0.55965 -1.0380175 -0.00455l-0.033848750000000004 -0.1573c-0.441545 -2.051075 -2.0595575 -3.6266749999999996 -4.093854375 -3.9866125Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="currentColor"
      />
    </g>
  </SvgIcon>
);

export default FileSparkIcon;
