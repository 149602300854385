import { SearchInput } from '@application/components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDebounceCallback } from 'usehooks-ts';
import { OnReportsFilterChange, ReportsFilters } from '../hooks/useReports';

type Props = {
  filters: ReportsFilters;
  searching: boolean;
  updateFilter: OnReportsFilterChange<keyof ReportsFilters>;
};

const ReportsFiltersFields = ({ filters, searching, updateFilter }: Props) => {
  const { t } = useTranslation();

  const onDebouncedAddressChange = useDebounceCallback(updateFilter('address'), 300);

  return (
    <Box>
      <SearchInput
        defaultValue={filters.address}
        onChange={onDebouncedAddressChange}
        onClear={() => updateFilter('address')('')}
        placeholder={t('reports.list.filters.address')}
        searching={searching}
      />
    </Box>
  );
};

export default ReportsFiltersFields;
