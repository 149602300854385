import { Routes } from '@application/routes';
import { SubscriptionPlan, UserRole } from '@domain/graphql.types';
import { MapOutlined, SettingsOutlined } from '@mui/icons-material';
import { ReactElement } from 'react';

export type MenuItem = {
  children?: MenuItem[];
  translationKey: string;
  icon?: ReactElement;
  to: Routes;
  roles?: UserRole[];
  plans?: SubscriptionPlan[];
  activeFor?: Routes[];
};

const menuItems: MenuItem[] = [
  {
    translationKey: 'navigation.siteLookup',
    icon: <MapOutlined fontSize="small" />,
    to: Routes.SITE_LOOKUP,
    children: [
      {
        translationKey: 'navigation.singleAddress',
        to: Routes.SITE_LOOKUP,
      },
      {
        translationKey: 'navigation.bulk',
        to: Routes.BULK_SEARCH,
        plans: [SubscriptionPlan.Business, SubscriptionPlan.Premium, SubscriptionPlan.Starter],
      },
      {
        translationKey: 'navigation.reports',
        to: Routes.REPORTS,
      },
    ],
  },
  {
    translationKey: 'navigation.settings',
    icon: <SettingsOutlined fontSize="small" />,
    to: Routes.TENANT_USERS,
    roles: [UserRole.Admin, UserRole.Owner],
    activeFor: [Routes.TENANT_INVITATIONS, Routes.TENANT_SUBSCRIPTION],
  },
];

export default menuItems;
