import { ErrorSnackbar } from '@application/components';
import { FeedbackContext, UserContext } from '@application/contexts';
import { usePagination, useSortBy } from '@application/hooks';
import { flattenEdges } from '@application/utils/data-utils';
import { downloadFile } from '@application/utils/file-utils';
import { extractErrorCodes } from '@application/utils/urql-utils';
import { ReportSortBy, ReportType, SortDirection } from '@domain/graphql.types';
import { useDownloadReportMutation, useRegenerateBulkReportMutation, useRegenerateSingleAddressReportMutation, useReportsQuery } from '@domain/index';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsFilters } from '../hooks/useReports';

type Props = {
  filters?: ReportsFilters;
};

const useReportsList = ({ filters }: Props) => {
  const { sortBy, onSortChange } = useSortBy<ReportSortBy>({ defaultSort: { by: ReportSortBy.CreatedAt, direction: SortDirection.Desc } });
  const { pagination, currentPage, handlePaginationChange, setCursors, handleRowsPerPageChange, resetPagination } = usePagination();

  const { t } = useTranslation();

  const { setFeedback } = useContext(FeedbackContext);
  const { refreshUser } = useContext(UserContext);

  const { data, fetching, reexecuteQuery } = useReportsQuery({
    variables: {
      filterBy: {
        address: filters?.address || undefined,
      },
      sortBy: sortBy ? [sortBy] : undefined,
      ...pagination,
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to reset pagination on filters update
  useEffect(() => {
    resetPagination();
  }, [filters?.address, resetPagination]);

  const { downloadingReport, downloadReport } = useDownloadReportMutation();
  const { generatingReport, regenerateSingleAddressReport } = useRegenerateSingleAddressReportMutation();
  const { regenerateBulkReport } = useRegenerateBulkReportMutation();

  const handleReportDownload = useCallback(
    (reportId: string) => async () => {
      const { data, error } = await downloadReport({ input: { reportId } });

      const errors = extractErrorCodes(error);

      if (errors.length > 0 || !data?.downloadReport.link) {
        const errorMessage = errors.find((e) => e.status === 422)
          ? t('reports.list.error.download.messageUnprocessable')
          : t('reports.list.error.download.message');

        setFeedback(<ErrorSnackbar message={errorMessage} onClose={() => setFeedback(null)} title={t('reports.list.error.download.title')} />);
        return;
      }

      downloadFile(data.downloadReport.link);
    },
    [downloadReport, setFeedback, t],
  );

  const handleSingleAddressReportRegeneration = useCallback(
    async (reportId: string) => {
      const { data, error } = await regenerateSingleAddressReport({ input: { reportId } });

      const errors = extractErrorCodes(error);

      if (errors.length > 0 || !data?.regenerateSingleAddressReport.link) {
        const errorMessage = errors.find((e) => e.status === 422)
          ? t('reports.list.error.regenerate.messageUnprocessable')
          : t('reports.list.error.regenerate.message');

        setFeedback(<ErrorSnackbar message={errorMessage} onClose={() => setFeedback(null)} title={t('reports.list.error.regenerate.title')} />);
        return;
      }

      downloadFile(data.regenerateSingleAddressReport.link);

      refreshUser();
      resetPagination();
      reexecuteQuery();
    },
    [reexecuteQuery, refreshUser, regenerateSingleAddressReport, resetPagination, setFeedback, t],
  );

  const handleBulkReportRegeneration = useCallback(
    async (reportId: string) => {
      const { error } = await regenerateBulkReport({ input: { reportId } });

      const errors = extractErrorCodes(error);

      if (errors.length > 0) {
        const errorMessage = errors.find((e) => e.status === 422)
          ? t('reports.list.error.regenerate.messageUnprocessable')
          : t('reports.list.error.regenerate.message');

        setFeedback(<ErrorSnackbar message={errorMessage} onClose={() => setFeedback(null)} title={t('reports.list.error.regenerate.title')} />);
        return;
      }

      resetPagination();
      reexecuteQuery();
    },
    [reexecuteQuery, regenerateBulkReport, resetPagination, setFeedback, t],
  );

  const handleReportRegeneration = useCallback(
    (reportId: string, reportType: ReportType) => () => {
      if (reportType === ReportType.Single) {
        handleSingleAddressReportRegeneration(reportId);
      } else if (reportType === ReportType.Bulk) {
        handleBulkReportRegeneration(reportId);
      }
    },
    [handleBulkReportRegeneration, handleSingleAddressReportRegeneration],
  );

  const refreshReports = useCallback(() => {
    resetPagination();
    reexecuteQuery();
  }, [reexecuteQuery, resetPagination]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: setCursors won't update
  useEffect(() => {
    setCursors({
      startCursor: data?.reports.page.pageInfo?.startCursor || undefined,
      endCursor: data?.reports.page.pageInfo?.endCursor || undefined,
    });
  }, [data?.reports.page.pageInfo]);

  return {
    data: flattenEdges(data?.reports.page.edges?.slice() || []),
    pagination: {
      count: data?.reports.pageData?.count || 0,
      currentPage,
      handlePaginationChange,
      handleRowsPerPageChange,
      hideOnSmallTotalCount: true,
      resetPagination,
    },
    sorting: {
      onSortChange,
      sortBy,
    },
    actions: {
      handleReportDownload,
      handleReportRegeneration,
      refreshReports,
    },
    state: {
      downloadingReport,
      fetching,
      generatingReport,
    },
  };
};

export default useReportsList;
