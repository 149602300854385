import 'mapbox-gl/dist/mapbox-gl.css';

import { CurrentPositionPin } from '@application/assets';
import { ExtendMapControl, clusterCountLayer, clusterLayer, comingSoonPointLayer, trackedPointLayer, untrackedPointLayer } from '@application/components';
import { GeoCoordinates, Site } from '@domain/graphql.types';
import { Box, useTheme } from '@mui/material';
import { useCallback, useContext, useRef } from 'react';
import ReactMap, { Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { useResizeObserver } from 'usehooks-ts';
import MapPin from '../components/MapPin';
import SiteLookupContext from '../context/SiteLookupContext';
import LegendMapControl from './LegendMapControl';
import useSiteLookupMap from './useSiteLookupMap';

type Props = {
  center?: GeoCoordinates;
  fetchingSites: boolean;
  searchRadius: number;
  showRadiusArea?: boolean;
  sites?: readonly Site[];
};

const SiteLookupMap = ({ center, fetchingSites, searchRadius, showRadiusArea, sites }: Props) => {
  const containerRef = useRef(null);

  const { isMapExtended, toggleIsMapExtended } = useContext(SiteLookupContext);

  const {
    actions: { setSelectedSite },
    layers: { radiusCircle },
    map: { lib, style },
    refs: { map },
    state: { allSites, displayExtendControl, mapCenter, mapZoom, selectedSite },
  } = useSiteLookupMap({ center, searchRadius, sites });

  const {
    palette: { primary },
  } = useTheme();

  const onResize = useCallback(() => {
    map.current?.resize();
  }, [map]);

  useResizeObserver({ ref: containerRef, onResize });

  return (
    <Box ref={containerRef} sx={{ height: '100%', width: '100%' }}>
      <ReactMap
        ref={map}
        mapLib={lib}
        mapStyle={style}
        initialViewState={{
          latitude: mapCenter.latitude,
          longitude: mapCenter.longitude,
          zoom: mapZoom,
        }}
        dragRotate={false}
      >
        {showRadiusArea && (
          <Source id="map-date-source" type="geojson" data={radiusCircle}>
            <Layer
              beforeId="clusters"
              id="center-radius-circle"
              type="fill"
              paint={{
                'fill-color': primary.dark,
                'fill-opacity': 0.12,
              }}
            />

            <Layer
              beforeId="clusters"
              id="center-radius-outer-line"
              type="line"
              paint={{
                'line-width': 2,
                'line-color': primary.main,
              }}
            />

            {!fetchingSites &&
              sites?.map(({ id, coordinates: { latitude, longitude }, status, trackingState }) => (
                <Marker
                  key={id}
                  onClick={() => setSelectedSite({ id, origin: 'map' })}
                  latitude={latitude}
                  longitude={longitude}
                  scale={1}
                  style={{ cursor: 'pointer', zIndex: selectedSite?.id === id ? 10 : 'unset' }}
                >
                  <MapPin isSelected={selectedSite?.id === id} state={trackingState} status={status} />
                </Marker>
              ))}
            <Marker latitude={mapCenter.latitude} longitude={mapCenter.longitude} scale={1} style={{ zIndex: 5 }}>
              <CurrentPositionPin />
            </Marker>
          </Source>
        )}

        <Source id="all_sites" type="geojson" data={allSites} cluster={true} clusterMaxZoom={14} clusterRadius={50}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...comingSoonPointLayer} />
          <Layer {...trackedPointLayer} />
          <Layer {...untrackedPointLayer} />
        </Source>

        <NavigationControl position="bottom-right" showCompass={false} />

        {displayExtendControl && <ExtendMapControl handleToggleMapExtended={toggleIsMapExtended} isMapExtended={isMapExtended} />}

        <LegendMapControl />
      </ReactMap>
    </Box>
  );
};

export default SiteLookupMap;
