import { Query, QueryTenantUsersArgs } from '@domain/graphql.types';
import { QueryTenantUsers } from '@infrastructure/graphql';
import { useQuery } from 'urql';

const useTenantUsersQuery = (input?: QueryTenantUsersArgs) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'tenantUsers'>, QueryTenantUsersArgs>({
    query: QueryTenantUsers,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useTenantUsersQuery;
