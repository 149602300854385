import { PopoverFilter } from '@application/components/filters';
import { TrackChangesOutlined } from '@mui/icons-material';
import { Box, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const defaultRadius = 5;

type Props = {
  onChange?: (value: number) => void;
  readonly?: boolean;
  value: number;
};

const RadiusFilter = ({ onChange, readonly, value }: Props) => {
  const { t } = useTranslation();

  const valueLabelFormat = (value: number) => t('suffix.unit.mile', { count: value });

  return (
    <PopoverFilter
      icon={<TrackChangesOutlined />}
      name={t('siteLookup.filters.radius')}
      onReset={() => onChange?.(defaultRadius)}
      readonly={readonly}
      valueLabel={t('suffix.unit.mileUpTo', { count: value })}
    >
      <Box minWidth={460} paddingLeft={2} paddingRight={3}>
        <Slider
          onChange={(_, value) => onChange?.(value as number)}
          value={value}
          step={1}
          min={1}
          max={20}
          marks={[
            { value: 1, label: 1 },
            { value: 5, label: 5 },
            { value: 10, label: 10 },
            { value: 15, label: 15 },
            { value: 20, label: 20 },
          ]}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          sx={{
            '& .MuiSlider-valueLabel': { backgroundColor: 'primary.main' },
            '& .MuiSlider-mark': { borderRadius: 100, height: 6, width: 6 },
            '& .MuiSlider-markLabel': {
              typography: 'body1',
              ':not(.MuiSlider-markLabel ~ .MuiSlider-markLabel)': { paddingLeft: 1 },
              ':not(:has(~ .MuiSlider-markLabel))': { paddingRight: 1 },
            },
          }}
        />
      </Box>
    </PopoverFilter>
  );
};

export default RadiusFilter;
