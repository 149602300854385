import { Mutation, MutationRegenerateSingleAddressReportArgs } from '@domain/graphql.types';
import { MutationRegenerateSingleAddressReport } from '@infrastructure/graphql';
import { useMutation } from 'urql';

const useRegenerateSingleAddressReportMutation = () => {
  const [{ data, fetching, error }, regenerateSingleAddressReport] = useMutation<
    Pick<Mutation, 'regenerateSingleAddressReport'>,
    MutationRegenerateSingleAddressReportArgs
  >(MutationRegenerateSingleAddressReport);

  return {
    data,
    generatingReport: fetching,
    error,
    regenerateSingleAddressReport,
  };
};

export default useRegenerateSingleAddressReportMutation;
