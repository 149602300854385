import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  background: {
    paper: '#FFFFFF',
  },
  primary: {
    main: '#503DFF',
    dark: '#1C31EF',
    light: '#B5A2FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#00FFFB',
    dark: '#00B8B5',
    light: '#ADFFFE',
    contrastText: '#000000',
  },
  error: {
    main: '#C12B2A',
    dark: '#A51E2A',
    light: '#EB917D',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#F1B805',
    dark: '#8B6000',
    light: '#FEF7CB',
    contrastText: '#694601',
  },
  success: {
    main: '#2E7D32',
    dark: '#1C5E1F',
    light: '#4CAF51',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0870DB',
    dark: '#043F9D',
    light: '#65B9F4',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#000000',
    secondary: '#595959',
    disabled: '#BDBDBD',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  divider: '#E5E5E5',
};
