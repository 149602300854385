import { useEventListener } from 'usehooks-ts';
import useAuthentication from './useAuthentication';

export const useLogout = () => {
  const { logout } = useAuthentication();

  const execute = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };
  useEventListener('paren-logout', execute);

  return {
    execute,
  };
};

export const dispatchLogout = async () => {
  window.dispatchEvent(new Event('paren-logout'));
};
