import { ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { version } from '../../../../../../../../package.json';
import { useContext } from 'react';
import { UserContext } from '@application/contexts';

const VersionMenuItem = () => {
  const { t } = useTranslation();

  const { version: apiVersion } = useContext(UserContext);

  return (
    <>
      <ListItem dense>
        <ListItemText secondary={t('version.portal', { version })} />
      </ListItem>

      <ListItem dense>
        <ListItemText secondary={t('version.portalApi', { version: apiVersion })} />
      </ListItem>
    </>
  );
};

export default VersionMenuItem;
