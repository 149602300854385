import { gql } from '@urql/core';

const TenantBillingCustomerSecret = gql`
  query TenantBillingCustomerSecret(
    $input: GetTenantBillingCustomerSecretRequest!,
  ) {
    tenantBillingCustomerSecret(
      input: $input,
    ) {
      secret
    }
  }
`;

export default TenantBillingCustomerSecret;
