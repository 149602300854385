import { PageLoader } from '@application/components';
import { Routes } from '@application/routes/routes';
import { useAuthentication, useLogout } from '@domain/authentication';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthGuardProps = {
  children: React.ReactNode;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  useLogout();

  const { isAuthenticated, isLoading } = useAuthentication();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate(Routes.HOME, { replace: true });
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <PageLoader />;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default AuthGuard;
