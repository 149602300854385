import { Router } from '@application/routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { initSentry } from '@infrastructure/sentry';
import { AuthenticationProvider, FeedbackProvider, GqlProvider, LanguageProvider, ThemeProvider, UserProvider } from './providers';

if (import.meta.env.DEV && import.meta.env.VITE_MOCK_BACKEND === 'true') {
  const { worker } = await import('../mocks/browser');
  await worker.start();
}

initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <LanguageProvider>
      <AuthenticationProvider>
        <GqlProvider>
          <UserProvider>
            <ThemeProvider>
              <FeedbackProvider>
                <Router />
              </FeedbackProvider>
            </ThemeProvider>
          </UserProvider>
        </GqlProvider>
      </AuthenticationProvider>
    </LanguageProvider>
  </React.StrictMode>,
);
