import { dottedBorderVector, UploadFileIcon } from '@application/assets';
import { Box, Link, Typography, alpha, useTheme } from '@mui/material';
import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  dropError?: string | null;
  uploadFile: (files: File[]) => void;
};

const BulkDropzone = ({ dropError, uploadFile }: Props) => {
  const { t } = useTranslation();

  const {
    palette: { primary },
  } = useTheme();

  const getDropZoneBorderColor = useCallback(
    (isDragActive: boolean) => {
      if (isDragActive) {
        return 'primary.main';
      }

      if (dropError) {
        return 'error.main';
      }

      return 'text.disabled';
    },
    [dropError],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Dropzone
        onDrop={uploadFile}
        maxFiles={1}
        multiple={false}
        accept={{ 'application/csv': ['.csv'], 'text/csv': ['.csv'] }}
        noClick
        noKeyboard
        useFsAccessApi={false}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => (
          <Box
            {...getRootProps()}
            sx={{
              position: 'relative',
              backgroundColor: isDragActive ? alpha(primary.main, 0.1) : 'unset',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 180,
              gap: 2,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                borderRadius: 'inherit',
                backgroundColor: getDropZoneBorderColor(isDragActive),
                mask: `url("${dottedBorderVector}")`,
              }}
            />

            <input {...getInputProps()} />

            <UploadFileIcon sx={{ width: '2em', height: '2em', color: isDragActive ? 'primary.dark' : '#7A7A7A' }} />

            <Typography variant="body2" fontWeight={500}>
              <Trans
                i18nKey={'bulkSearch.dropZone.dragAndDrop'}
                t={t}
                components={{
                  ChooseFile: (
                    <Link color="inherit" component="button" variant="body2" fontWeight={500} onClick={open} sx={{ marginBottom: '2.5px' }}>
                      {t('bulkSearch.dropZone.chooseFile')}
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Box>
        )}
      </Dropzone>

      {dropError && (
        <Typography color="error" variant="body2">
          {dropError}
        </Typography>
      )}
    </Box>
  );
};

export default BulkDropzone;
