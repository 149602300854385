import { UserContext } from '@application/contexts';
import { UserRole } from '@domain/graphql.types';
import { useCallback, useContext } from 'react';

const useIsAuthorized = () => {
  const { role } = useContext(UserContext);

  const checkIsAuthorized = useCallback((roles?: UserRole[]) => (roles ? role && roles.includes(role) : true), [role]);

  return checkIsAuthorized;
};

export default useIsAuthorized;
