import { Table } from '@application/components/table';
import { TablePaginationProps } from '@application/components/table/Table';
import { UserContext } from '@application/contexts';
import { formatUtcStringDateTime } from '@application/utils/date-utils';
import { Report, ReportSortBy, ReportStatus, ReportType, SortDirection } from '@domain/graphql.types';
import { Box, Chip, TableContainer, Tooltip, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ActionsMenu from './ActionsMenu';

const getStatusChipColor = (status: ReportStatus) => {
  switch (status) {
    case ReportStatus.Completed:
      return 'success';
    case ReportStatus.InProgress:
      return 'warning';
    case ReportStatus.Error:
      return 'error';
  }
};

type Props = {
  data: Report[];
  handleReportDownload: (reportId: string) => () => void;
  handleReportRegeneration: (reportId: string, reportType: ReportType) => () => void;
  pagination?: TablePaginationProps;
  onSortChange?: (id: ReportSortBy | undefined) => void;
  sortBy?: { by: ReportSortBy; direction: SortDirection };
};

const ReportsList = ({ data, handleReportDownload, handleReportRegeneration, onSortChange, pagination, sortBy }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { user } = useContext(UserContext);

  const renderUserName = useCallback(
    (report: Report) => {
      if (report.userId === user?.id) {
        return t('reports.list.content.you');
      }

      return report.user.displayName;
    },
    [t, user],
  );

  return (
    <>
      <TableContainer>
        <Table
          columns={[
            {
              label: t('reports.list.header.user'),
              renderCell: (info) => (
                <Typography variant="body2" component="span">
                  {renderUserName(info)}
                </Typography>
              ),
            },
            {
              label: t('reports.list.header.name'),
              renderCell: (info) => (
                <Tooltip title={info.name} arrow placement="top">
                  <Box sx={{ maxWidth: 240, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <Typography variant="body2" component="span">
                      {info.name}
                    </Typography>
                  </Box>
                </Tooltip>
              ),
            },
            {
              label: t('reports.list.header.addresses'),
              renderCell: (info) => (
                <span>
                  <Tooltip arrow placement="top" title={info.addresses[0]}>
                    <Chip label={info.addresses[0]} sx={{ maxWidth: 240 }} />
                  </Tooltip>

                  {info.addresses.length > 1 && <Chip label={`+${info.addresses.length - 1}`} sx={{ marginLeft: 0.5 }} />}
                </span>
              ),
            },
            {
              label: t('reports.list.header.range'),
              renderCell: (info) => (
                <Typography variant="body2" component="span">
                  {info.filters?.range ? t('suffix.unit.mile', { count: info.filters?.range }) : '-'}
                </Typography>
              ),
            },
            {
              label: t('reports.list.header.status'),
              renderCell: (info) => (
                <Tooltip
                  disableInteractive={info.status !== ReportStatus.Error}
                  arrow
                  placement="top"
                  title={info.status === ReportStatus.Error ? t('reports.list.errorStatus') : null}
                >
                  <Chip label={t(`enum.reportStatus.${info.status}`)} color={getStatusChipColor(info.status)} />
                </Tooltip>
              ),
            },
            {
              label: t('reports.list.header.aggregation'),
              renderCell: (info) => <Chip label={t(`enum.reportAggregation.${info.aggregation}`)} />,
            },
            {
              label: t('reports.list.header.generatedAt'),
              renderCell: (info) => (
                <Typography variant="body2" component="span">
                  {formatUtcStringDateTime(info.createdAt, language)}
                </Typography>
              ),
              sortId: ReportSortBy.CreatedAt,
            },
            {
              label: '',
              renderCell: (info) => (
                <ActionsMenu
                  canRegenerate={info.canRegenerate}
                  handleReportDownload={handleReportDownload(info.id)}
                  handleReportRegeneration={handleReportRegeneration(info.id, info.type)}
                  nextRegenerationDate={info.nextRegenerationDate}
                  status={info.status}
                />
              ),
            },
          ]}
          data={data}
          sortBy={sortBy}
          onSortChange={onSortChange}
          pagination={pagination}
        />
      </TableContainer>
    </>
  );
};

export default ReportsList;
