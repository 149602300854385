import LogoParen from '@application/assets/LogoParen';
import { LinkComponent } from '@application/components';
import { FeedbackOutlined, HelpOutline, PrivacyTipOutlined } from '@mui/icons-material';
import { Box, CSSObject, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SideBarItem from './SideBarItem';
import menuItems from './SideBarItems';
import SideBarToggler from './SideBarToggler';
import useIsAuthorized from './useIsAuthorized';

const drawerWidth = 230;

const openedMixin: CSSObject = {
  width: drawerWidth,
  overflowX: 'hidden',
  backgroundColor: '#FAFAFA',
};

const closedMixin: CSSObject = {
  overflowX: 'hidden',
  width: '70px',
  backgroundColor: '#FAFAFA',
};

const SidebarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: theme.zIndex.drawer,
  ...(open && {
    ...openedMixin,
    '& .MuiDrawer-paper': openedMixin,
  }),
  ...(!open && {
    ...closedMixin,
    '& .MuiDrawer-paper': closedMixin,
  }),
}));

type Props = {
  isSideBarOpen: boolean;
  toggleSideBarOpen: () => void;
};

const SideBar = ({ isSideBarOpen, toggleSideBarOpen }: Props) => {
  const { t } = useTranslation();

  const checkIsAuthorized = useIsAuthorized();

  return (
    <SidebarDrawer variant="permanent" anchor="left" open={isSideBarOpen}>
      <Box display="flex" alignItems="center" width="100%" minHeight="var(--top-bar-height)" paddingInlineStart={2} paddingBlockStart={2}>
        <Box height="auto" width="100%" maxWidth="140px">
          <LogoParen height={40} full={isSideBarOpen} />
        </Box>
      </Box>

      <Box component="nav" display="flex" flexDirection="column" justifyContent="space-between" height="100%" sx={{ marginBlockStart: 1.5 }}>
        <List>
          {menuItems
            .filter(({ roles }) => checkIsAuthorized(roles))
            .map((entry) => (
              <Fragment key={entry.translationKey}>
                <SideBarItem isSideBarOpen={isSideBarOpen} item={entry} />

                {entry.children && isSideBarOpen && (
                  <Collapse component="li" in timeout="auto" unmountOnExit>
                    <List disablePadding sx={{ marginLeft: 3 }}>
                      {entry.children
                        .filter(({ roles }) => checkIsAuthorized(roles))
                        .map((entry) => (
                          <SideBarItem child key={entry.translationKey} isSideBarOpen={isSideBarOpen} item={entry} />
                        ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            ))}
        </List>

        <List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: 1 }}>
          <ListItem dense>
            <ListItemButton href="mailto:support@paren.app" sx={{ paddingX: '7px', gap: 1 }} LinkComponent={LinkComponent}>
              <ListItemIcon sx={{ minWidth: '0' }}>
                <HelpOutline fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ visibility: isSideBarOpen ? 'visible' : 'hidden' }}>{t('navigation.gettingHelp')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem dense>
            <ListItemButton
              href={import.meta.env.VITE_REACT_APP_PRIVACY_POLICY_LINK}
              sx={{ paddingX: '7px', gap: 1 }}
              LinkComponent={LinkComponent}
              target="_blank"
            >
              <ListItemIcon sx={{ minWidth: '0' }}>
                <PrivacyTipOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ visibility: isSideBarOpen ? 'visible' : 'hidden' }}>{t('navigation.privacyPolicy')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem dense>
            <ListItemButton href="mailto:feedback@paren.app" sx={{ paddingX: '7px', gap: 1 }} LinkComponent={LinkComponent}>
              <ListItemIcon sx={{ minWidth: '0' }}>
                <FeedbackOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ visibility: isSideBarOpen ? 'visible' : 'hidden' }}>{t('navigation.feedback')}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>

      <Divider sx={{ marginTop: 2 }} />

      <SideBarToggler isOpen={isSideBarOpen} toggle={toggleSideBarOpen} />
    </SidebarDrawer>
  );
};

export default SideBar;
