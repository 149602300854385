import { Box, Button, ClickAwayListener, Divider, Menu, Theme, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import LogoutMenuItem from './menu-items/LogoutMenuItem';
import UserAvatar from './menu-items/UserAvatar';
import UserHeaderMenuItem from './menu-items/UserHeaderMenuItem';
import VersionMenuItem from './menu-items/VersionMenuItem';

const UserMenu = () => {
  const theme = useTheme<Theme>();

  // Set an anchor for the userMenu and handle the open/close
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ position: 'relative' }}>
        <Button
          ref={anchorRef}
          id="user-button"
          color="inherit"
          aria-controls={open ? 'user-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            textTransform: 'none',
            gap: { sm: 0.75, md: 1.75 },
            fontSize: theme.typography.body1,
            fontWeight: 600,
          }}
        >
          <UserAvatar />
        </Button>

        <Menu
          id="user-menu"
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          elevation={2}
          MenuListProps={{
            'aria-labelledby': 'user-button',
            sx: { minWidth: anchorRef.current?.offsetWidth },
          }}
        >
          <UserHeaderMenuItem />

          <Divider sx={{ marginY: 1 }} />

          <VersionMenuItem />

          <Divider sx={{ marginY: 1 }} />

          <LogoutMenuItem />
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default UserMenu;
