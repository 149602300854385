import { SvgIcon, SxProps, Theme } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  size?: 'inherit' | 'large' | 'medium' | 'small';
};

const CheckCircleIcon = ({ size = 'small', sx }: Props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.625 -0.625 24 24" fontSize={size} sx={sx}>
    <g>
      <path
        d="M11.375 21.9375c5.8335875 0 10.5625 -4.7289125 10.5625 -10.5625C21.9375 5.54149375 17.2085875 0.8125 11.375 0.8125 5.54149375 0.8125 0.8125 5.54149375 0.8125 11.375c0 5.8335875 4.72899375 10.5625 10.5625 10.5625Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        stroke="currentColor"
      />
      <path
        d="m16.11510875 7.71875 -6.5 8.125 -3.25 -2.4375"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        stroke="currentColor"
      />
    </g>
  </SvgIcon>
);

export default CheckCircleIcon;
