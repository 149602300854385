import { authExchange } from '@urql/exchange-auth';
import { Client, fetchExchange } from 'urql';
import { v4 as uuid } from 'uuid';

declare global {
  interface WindowEventMap {
    'paren-logout': CustomEvent;
  }
}

const client = ({ getAccessTokenSilently, isAuthenticated }: { getAccessTokenSilently: () => Promise<string>; isAuthenticated: boolean }) =>
  new Client({
    url: `${import.meta.env.VITE_BACKEND_URL}/graphql`,
    requestPolicy: 'network-only',
    exchanges: [
      authExchange(async (utils) => {
        let token: string | null = null;

        if (isAuthenticated) {
          try {
            token = await getAccessTokenSilently();
          } catch (e) {
            window.dispatchEvent(new Event('paren-logout'));
          }
        }

        return {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          async refreshAuth() {
            window.dispatchEvent(new Event('paren-logout'));
          },
          didAuthError(error) {
            const isGraphQLAuthError = error.graphQLErrors.some((e) => e.extensions?.code === 'Unauthorized' || e.extensions?.code === 'UNAUTHENTICATED');
            return isGraphQLAuthError || error.response?.status === 401;
          },
        };
      }),
      fetchExchange,
    ],
    fetchOptions: {
      credentials: 'omit',
      headers: {
        // See https://github.com/mswjs/msw/issues/1593
        Accept: '*/*',
        'X-Correlation-ID': uuid(),
        'apollo-require-preflight': 'true',
      },
    },
  });

export default client;
