import { CheckCircleOutline } from '@mui/icons-material';
import { LinearProgress, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OnboardingComplete = () => {
  const { t } = useTranslation();

  const { palette } = useTheme();

  return (
    <>
      <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />

      <Stack gap={4} alignItems="center">
        <CheckCircleOutline sx={{ color: palette.success.dark, height: 96, width: 96 }} />

        <Typography variant="h1">{t('onboarding.complete.title')}</Typography>

        <Typography variant="body1">{t('onboarding.complete.description')}</Typography>
      </Stack>
    </>
  );
};

export default OnboardingComplete;
