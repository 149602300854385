import { FeedbackContext } from '@application/contexts';
import { Portal } from '@mui/material';
import React, { PropsWithChildren, useMemo, useState } from 'react';

const FeedbackProvider = ({ children }: PropsWithChildren) => {
  const [feedback, setFeedback] = useState<React.ReactNode | undefined>(undefined);

  const contextValue = useMemo(() => ({ feedback, setFeedback }), [feedback]);

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
      {feedback && <Portal>{feedback}</Portal>}
    </FeedbackContext.Provider>
  );
};

export default React.memo(FeedbackProvider);
