import { MutationSendOtpVerification } from '@infrastructure/graphql';
import { useMutation } from 'urql';
import { Mutation, MutationSendOtpVerificationArgs } from '../graphql.types';

const useSendOtpVerification = () => {
  const [{ data, fetching, error }, sendOtpVerification] = useMutation<Pick<Mutation, 'sendOtpVerification'>, MutationSendOtpVerificationArgs>(
    MutationSendOtpVerification,
  );

  return {
    sendOtpVerification,
    data,
    fetching,
    error,
  };
};

export default useSendOtpVerification;
