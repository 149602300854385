import { gql } from '@urql/core';
import { UserWithDefaultTenant, getPage } from '../fragments';

const TenantUsers = gql`
  ${UserWithDefaultTenant}

  query TenantUsers(
    $sortBy: [TenantUsersSortDirective!],
    $filterBy: TenantUsersFilters,
    $before: String,
    $after: String,
    $first: Float,
    $last: Float
  ) {
    tenantUsers(
      sortBy: $sortBy,
      filterBy: $filterBy,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      ${getPage(['UserWithDefaultTenantFragment'])}
    }
  }
`;

export default TenantUsers;
