import { SideBar, TopBar } from '@application/components';
import { Box, Container } from '@mui/material';
import React, { useCallback, useState } from 'react';

type PrivateLayoutProps = {
  children: React.ReactNode;
};

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const [isSideBarOpen, setSideBarOpen] = useState(true);

  const toggleSideBarOpen = useCallback(() => setSideBarOpen(!isSideBarOpen), [isSideBarOpen]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
      }}
    >
      <SideBar isSideBarOpen={isSideBarOpen} toggleSideBarOpen={toggleSideBarOpen} />

      <Box sx={{ flexGrow: 1 }}>
        <TopBar />
        <Box component="main">
          <Container disableGutters maxWidth={false} sx={{ height: '100%' }}>
            {children}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
