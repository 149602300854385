import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  toggle: () => void;
};

const SideBarToggler = ({ isOpen, toggle }: Props) => {
  const { t } = useTranslation();

  return (
    <IconButton
      aria-label={isOpen ? t('button.reduceSideNav') : t('button.openSideNav')}
      onClick={toggle}
      sx={{
        paddingX: 2,
        justifyContent: isOpen ? 'flex-end' : 'center',
        '&&': { minHeight: '70px', borderRadius: '0' },
      }}
    >
      {isOpen ? <KeyboardDoubleArrowLeft color="action" /> : <KeyboardDoubleArrowRight color="action" />}
    </IconButton>
  );
};

export default SideBarToggler;
