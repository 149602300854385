import { Box, CircularProgress } from '@mui/material';
import usePricingTable from './usePricingTable';

const PricingTable = () => {
  const { key, loadingSecret, pricingTableId, secret } = usePricingTable();

  return (
    <>
      {loadingSecret && (
        <Box display="flex" justifyContent="center">
          <CircularProgress sx={{ marginTop: 16 }} />
        </Box>
      )}

      {!loadingSecret && secret && <stripe-pricing-table customer-session-client-secret={secret} pricing-table-id={pricingTableId} publishable-key={key} />}
    </>
  );
};

export default PricingTable;
