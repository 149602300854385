import { ErrorSnackbar } from '@application/components';
import { FeedbackContext } from '@application/contexts';
import { Routes } from '@application/routes';
import { extractErrorCodes } from '@application/utils/urql-utils';
import { AddressErrors, ReportAggregation } from '@domain/graphql.types';
import { useGenerateBulkReportMutation, useValidateAddressesMutation } from '@domain/index';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useBulkSearchFilters from '../filters/useBulkSearchFilters';

export enum FileState {
  IDLE = 'IDLE',
  SUCCEEDED = 'SUCEEDED',
  READY = 'READY',
}

const useBulkSearch = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [dropError, setDropError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [fileState, setFileState] = useState(FileState.IDLE);
  const [isMapExtended, setIsMapExtended] = useState(false);

  const { setFeedback } = useContext(FeedbackContext);

  const { filters, updateFilter } = useBulkSearchFilters();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data, error, fetching, validateAddresses } = useValidateAddressesMutation();
  const { generatingReport, generateBulkReport } = useGenerateBulkReportMutation();

  const list = useMemo(
    () => ({
      addresses: data?.validateAddresses.addresses || [],
      invalidCount: data?.validateAddresses.invalidCount || 0,
      totalCount: data?.validateAddresses.totalCount || 0,
    }),
    [data],
  );

  const validAddresses = useMemo(() => list.addresses.filter(({ valid }) => valid), [list.addresses]);

  const validateAddressesErrors = useMemo(() => extractErrorCodes(error), [error]);

  useEffect(() => {
    if (!validateAddressesErrors.length) {
      return;
    }

    const messages = validateAddressesErrors.map(({ message }) => message);

    if (messages.includes(AddressErrors.InvalidHeaders)) {
      setValidationError(t('bulkSearch.dropZone.error.validation.messageInvalidHeaders'));
      return;
    }

    if (messages.includes(AddressErrors.DuplicateId)) {
      setValidationError(t('bulkSearch.dropZone.error.validation.messageDuplicateId'));
      return;
    }

    const invalidRecordError = validateAddressesErrors.find(({ message }) => message === AddressErrors.InvalidRecord);
    if (invalidRecordError) {
      setValidationError(t('bulkSearch.dropZone.error.validation.messageInvalidRecord', { line: invalidRecordError.error.cause?.line }));
      return;
    }

    setValidationError(t('bulkSearch.dropZone.error.validation.message'));
  }, [t, validateAddressesErrors]);

  useEffect(() => {
    if (!uploadedFiles) {
      return;
    }

    setFileState(FileState.IDLE);
    setValidationError(null);
    validateAddresses({ files: uploadedFiles });
  }, [uploadedFiles, validateAddresses]);

  useEffect(() => {
    if (data?.validateAddresses) {
      setFileState(FileState.SUCCEEDED);
      setTimeout(() => setFileState(FileState.READY), 3000);
    }
  }, [data]);

  const toggleIsMapExtended = useCallback(() => {
    setIsMapExtended((old) => !old);
  }, []);

  const uploadFile = useCallback(
    (files: File[]) => {
      if (!files || files.length === 0) {
        setDropError(t('bulkSearch.dropZone.error.upload.message'));
        return;
      }

      setDropError(null);
      setUploadedFiles(files);
    },
    [t],
  );

  const handleGenerateBulkReport = useCallback(
    async (aggregation: ReportAggregation) => {
      const { data } = await generateBulkReport({ addresses: validAddresses, aggregation });

      if (data?.generateBulkReport?.report.id) {
        navigate(Routes.REPORTS);
      } else {
        setFeedback(
          <ErrorSnackbar
            onClose={() => setFeedback(null)}
            message={t('bulkSearch.error.generateReport.message')}
            title={t('bulkSearch.error.generateReport.title')}
          />,
        );
      }
    },
    [generateBulkReport, navigate, setFeedback, t, validAddresses],
  );

  return {
    actions: {
      handleGenerateBulkReport,
      toggleIsMapExtended,
      uploadFile,
      updateFilter,
    },
    list,
    state: {
      dropError,
      file: uploadedFiles[0],
      fileState,
      filters,
      generatingReport,
      isMapExtended,
      showList: fileState === FileState.READY || fileState === FileState.SUCCEEDED,
      validAddresses,
      validatingAddresses: fetching,
      validationError,
    },
  };
};

export default useBulkSearch;
