import { Mutation, MutationGenerateSingleAddressSearchReportArgs } from '@domain/graphql.types';
import { MutationGenerateSingleAddressSearchReport } from '@infrastructure/graphql';
import { useMutation } from 'urql';

const useGenerateSingleAddressSearchReportMutation = () => {
  const [{ data, fetching, error }, generateReport] = useMutation<
    Pick<Mutation, 'generateSingleAddressSearchReport'>,
    MutationGenerateSingleAddressSearchReportArgs
  >(MutationGenerateSingleAddressSearchReport);

  return {
    data,
    fetching,
    error,
    generateReport,
  };
};

export default useGenerateSingleAddressSearchReportMutation;
