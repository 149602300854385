import { useCallback, useState } from 'react';
import useReportsList from '../list/useReportsList';

export type ReportsFilters = {
  address?: string;
};

export type OnReportsFilterChange<T extends keyof ReportsFilters> = (key: T) => (value: ReportsFilters[T]) => void;

const useReports = () => {
  const [filters, setFilters] = useState<ReportsFilters>({ address: '' });

  const updateFilter = useCallback(
    <T extends keyof ReportsFilters>(key: T) =>
      (value: ReportsFilters[T]) => {
        setFilters((old) => ({ ...old, [key]: value }));
      },
    [],
  );

  const {
    actions: { handleReportDownload, handleReportRegeneration, refreshReports },
    data,
    pagination,
    sorting: { onSortChange, sortBy },
    state: { fetching, generatingReport },
  } = useReportsList({ filters });

  return {
    actions: {
      refreshReports,
    },
    list: {
      data,
      handleReportDownload,
      handleReportRegeneration,
      onSortChange,
      pagination,
      sortBy,
    },
    filters: {
      filters,
      updateFilter,
    },
    state: {
      fetching,
      generatingReport,
    },
  };
};

export default useReports;
