import { lighten } from '@mui/material';

type Props = {
  height?: number;
  width?: number;
};

const ComingSoonPin = ({ height = 40, width = 40 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="ComingSoonPin">
      <g clip-path="url(#clip0_5439_32543)">
        <path
          d="M20 3.33331C13.5567 3.33331 8.33333 8.55665 8.33333 15C8.33333 23.3333 20 36.6666 20 36.6666C20 36.6666 31.6667 23.3333 31.6667 15C31.6667 8.55665 26.4433 3.33331 20 3.33331Z"
          fill="white"
        />
        <path
          d="M20 33.9307C19.8582 33.7551 19.7074 33.5666 19.5486 33.3662C18.4754 32.0118 17.0473 30.1245 15.6227 27.9876C14.1947 25.8455 12.7916 23.4847 11.7511 21.1807C10.6977 18.8482 10.0833 16.719 10.0833 15C10.0833 9.52315 14.5232 5.08331 20 5.08331C25.4768 5.08331 29.9167 9.52315 29.9167 15C29.9167 16.719 29.3023 18.8482 28.2488 21.1807C27.2084 23.4847 25.8053 25.8455 24.3772 27.9876C22.9526 30.1245 21.5246 32.0118 20.4514 33.3662C20.2926 33.5666 20.1417 33.7551 20 33.9307Z"
          stroke={lighten('#755BFF', 0.14)}
          strokeWidth="3.5"
        />
        <path d="M20.2564 8.33331L16.6667 16.2121H19.7436V21.6666L23.3333 13.7879H20.2564V8.33331Z" fill={lighten('#755BFF', 0.14)} />
      </g>
      <defs>
        <clipPath id="clip0_5439_32543">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComingSoonPin;
