import { Box, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { rem } from '@application/styles/utils';
import { BulkSearchAddressItem } from '@domain/graphql.types';

type Props = {
  address: BulkSearchAddressItem;
  isLast: boolean;
};

const ListCard = ({ address, isLast }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1} component="li">
      <Box display="flex" gap={2} justifyContent="space-between">
        <Stack>
          <Box display="flex" gap={2}>
            <Tooltip title={address.userDefinedId} arrow placement="top">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  fontWeight: '500',
                  color: 'text.secondary',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '4ch',
                }}
              >
                {address.userDefinedId}
              </Typography>
            </Tooltip>

            <Box display="flex" flexDirection="column" gap={0.5}>
              <Tooltip title={address.fullAddress} arrow placement="top">
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ fontWeight: '500', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '36ch' }}
                >
                  {address.fullAddress}
                </Typography>
              </Tooltip>

              {address.name ? (
                <Tooltip title={address.name} arrow placement="top">
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '22ch', fontWeight: '500', color: 'text.secondary' }}
                  >
                    {address.name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '22ch', fontWeight: '500', color: 'text.secondary' }}
                >
                  (no name)
                </Typography>
              )}
            </Box>
          </Box>
        </Stack>

        {!address.valid && (
          <Tooltip title={t('bulkSearch.list.invalidTooltip')} arrow placement="top">
            <Chip
              label={t('bulkSearch.list.invalid')}
              variant="outlined"
              sx={{
                borderColor: 'error.dark',
                color: 'error.dark',
                borderRadius: 0.5,
                height: 20,
                fontWeight: 'medium',
                fontSize: rem(12),
                textTransform: 'uppercase',
                '& > span': {
                  paddingX: '4px',
                },
              }}
            />
          </Tooltip>
        )}
      </Box>

      {!isLast && <Divider />}
    </Stack>
  );
};

export default ListCard;
