import { ReportAggregation, Tenant, TenantSubscription, User, UserRole } from '@domain/graphql.types';
import { createContext } from 'react';

export type UserContextProps = {
  error: Error | undefined;
  finishOnboarding: (user: User) => void;
  initializationCompleted: boolean;
  initializeUser: () => void;
  isEmailVerified: boolean;
  isInvitedUser: boolean;
  isLoading: boolean;
  hasFinishedOnboarding: boolean;
  hasSupportForAggregation: (aggregation: ReportAggregation) => boolean;
  refreshUser: () => void;
  user: User | undefined;
  role: UserRole | undefined;
  tenantSubscription: TenantSubscription | undefined;
  tenant: Tenant | undefined;
  isOnFreePlan: boolean;
  version: string | null;
};

const UserContext = createContext<UserContextProps>({
  error: undefined,
  finishOnboarding: () => {},
  initializationCompleted: false,
  initializeUser: () => {},
  isEmailVerified: false,
  isInvitedUser: false,
  isLoading: false,
  hasFinishedOnboarding: false,
  hasSupportForAggregation: () => false,
  refreshUser: () => {},
  user: undefined,
  role: undefined,
  tenantSubscription: undefined,
  tenant: undefined,
  isOnFreePlan: false,
  version: null,
});

export default UserContext;
