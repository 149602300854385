import { LanguageContext } from '@application/contexts';
import i18n from 'i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { getAuth0CurrentLanguage } from '../../utils/auth0-utils';
import './i18n';

type LanguageProviderProps = {
  children: ReactNode;
};

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const { t, i18n: i18nInstance } = useTranslation();
  const [auth0CurrentLanguage, setAuth0CurrentLanguage] = useState(getAuth0CurrentLanguage(i18nInstance.language));

  const changeLanguage = useMemo(
    () => (language: string) => {
      i18nInstance.changeLanguage(language);
      localStorage.setItem('language', language);
      setAuth0CurrentLanguage(getAuth0CurrentLanguage(language));
    },
    [i18nInstance],
  );

  const contextValue = useMemo(() => ({ changeLanguage, t, auth0CurrentLanguage }), [changeLanguage, t, auth0CurrentLanguage]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    }
  }, [changeLanguage]);

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>
    </I18nextProvider>
  );
};

export default LanguageProvider;
