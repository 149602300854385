import { gql } from '@urql/core';

const RegenerateSingleAddressReport = gql`
  mutation RegenerateSingleAddressReport(
    $input: RegenerateReportRequest!,
  ) {
    regenerateSingleAddressReport(
      input: $input,
    ) {
      link
    }
  }
`;

export default RegenerateSingleAddressReport;
