import { TypographyOptions } from '@mui/material/styles/createTypography';
import { rem } from './utils';

export const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
].join(',');

export const typography: TypographyOptions = {
  fontFamily,
  fontSize: 16,
  h1: { fontSize: rem(32), lineHeight: 40 / 32, fontWeight: 400 },
  h2: { fontSize: rem(28), lineHeight: 32 / 28 },
  h3: { fontSize: rem(24), lineHeight: 28 / 24 },
  h4: { fontSize: rem(22), lineHeight: 24 / 22 },
  h5: { fontSize: rem(20), lineHeight: 22 / 20 },
  h6: { fontSize: rem(18), lineHeight: 20 / 18 },
  subtitle1: { fontSize: rem(16), lineHeight: 18 / 16 },
  subtitle2: { fontSize: rem(14), lineHeight: 16 / 14 },
  body1: { fontSize: rem(16), lineHeight: 18 / 16 },
  body2: { fontSize: rem(14), lineHeight: 16 / 12 },
  caption: { fontSize: rem(10), lineHeight: 12 / 10 },
  overline: { fontSize: rem(12), lineHeight: 14 / 12 },
};
