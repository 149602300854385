import { ColorRing } from 'react-loader-spinner';

const spinner = () => (
  <ColorRing
    visible
    height="80"
    width="80"
    ariaLabel="color-ring-loading"
    wrapperStyle={{}}
    wrapperClass="color-ring-wrapper"
    colors={['#e4edf2', '#cde2ee', '#bbdcf0', '#a8daf9', '#92d2f9']}
  />
);

export default spinner;
