import { Routes } from '@application/routes';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ErrorBoundary = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '75vh' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
          width: 400,
        }}
      >
        <Typography variant="h1" fontWeight={600}>
          {t('errorBoundary.title')}
        </Typography>

        <Typography variant="body1">{t('errorBoundary.message')}</Typography>

        <Button href={Routes.SITE_LOOKUP} variant="contained">
          {t('button.backToHome')}
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorBoundary;
