import { gql } from '@urql/core';

const TenantUsers = gql`
  mutation InviteTenantUser($input: InviteTenantUserRequest!) {
    inviteTenantUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export default TenantUsers;
