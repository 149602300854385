import { SvgIcon, SxProps, Theme } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  size?: 'inherit' | 'large' | 'medium' | 'small';
};

const UploadFileIcon = ({ size = 'small', sx }: Props) => (
  <SvgIcon viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg" fontSize={size} sx={sx}>
    <path
      d="M40.7029 44.7027C40.7029 45.6527 40.3253 46.5641 39.6536 47.2358C38.982 47.9074 38.0706 48.285 37.1206 48.285H4.87968C3.92958 48.285 3.01842 47.9074 2.34659 47.2358C1.6748 46.5641 1.29736 45.6527 1.29736 44.7027V5.29717C1.29736 4.34706 1.6748 3.4359 2.34659 2.76408C3.01842 2.09227 3.92958 1.71484 4.87968 1.71484H28.1648L40.7029 14.253V44.7027Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M28.1647 23.2096L21.0001 16.0449L13.8354 23.2096"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M21 16.0449V35.7477" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </SvgIcon>
);

export default UploadFileIcon;
