import { AddressSearchResponse } from '@application/components/address-search/AddressSearch';
import { useCallback, useState } from 'react';

export type SiteLookupFilters = {
  address?: AddressSearchResponse;
  radius: number;
};

export type OnSiteLookupFilterChange<T extends keyof SiteLookupFilters> = (key: T) => (value: SiteLookupFilters[T]) => void;

const useSiteLookupFilters = () => {
  const [filters, setFilters] = useState<SiteLookupFilters>({ radius: 5 });

  const updateFilter = useCallback(
    <T extends keyof SiteLookupFilters>(key: T) =>
      (value: SiteLookupFilters[T]) => {
        setFilters((old) => ({ ...old, [key]: value }));
      },
    [],
  );

  return { filters, updateFilter };
};

export default useSiteLookupFilters;
