import DownloadReportDialog from '@application/views/site-lookup/components/DownloadReportDialog';
import { Refresh } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReportsFiltersFields from './filters/ReportsFiltersFields';
import useReports from './hooks/useReports';
import ReportsList from './list/ReportsList';

const Reports = () => {
  const { t } = useTranslation();

  const {
    actions: { refreshReports },
    list,
    filters,
    state: { fetching, generatingReport },
  } = useReports();

  return (
    <Container maxWidth={false} sx={{ maxWidth: 1380 }}>
      <Box display="flex" flexDirection="column" margin={3} gap={3}>
        <Typography variant="h1">{t('reports.heading')}</Typography>

        <Box display="flex" justifyContent="space-between">
          <ReportsFiltersFields {...filters} searching={fetching} />

          <Button variant="contained" startIcon={<Refresh />} onClick={refreshReports}>
            {t('reports.refresh')}
          </Button>
        </Box>

        <ReportsList {...list} />
      </Box>

      <DownloadReportDialog open={generatingReport} />
    </Container>
  );
};

export default Reports;
