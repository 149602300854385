import { Table } from '@application/components/table';
import { TablePaginationProps } from '@application/components/table/Table';
import { SortDirection, TenantUserSortBy, User } from '@domain/graphql.types';
import { TableContainer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: User[];
  pagination?: TablePaginationProps;
  onSortChange?: (id: TenantUserSortBy | undefined) => void;
  sortBy?: { by: TenantUserSortBy; direction: SortDirection };
};

const TenantUsersList = ({ data, onSortChange, pagination, sortBy }: Props) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table
        columns={[
          {
            label: t('list.header.name'),
            renderCell: (info) => (
              <Typography variant="body2" component="span">
                {info.displayName}
              </Typography>
            ),
            sortId: TenantUserSortBy.FullName,
          },
          {
            label: t('list.header.email'),
            renderCell: (info) => (
              <Typography variant="body2" component="span">
                {info.email}
              </Typography>
            ),
            sortId: TenantUserSortBy.Email,
          },
          {
            label: t('list.header.phone'),
            renderCell: (info) => (
              <Typography variant="body2" component="span">
                {info.phone}
              </Typography>
            ),
          },
          {
            label: t('list.header.role'),
            renderCell: (info) => <Typography variant="body2">{info.role ? t(`enum.userRole.${info.role.toLowerCase()}`) : '-'}</Typography>,
          },
          {
            label: t('list.header.status'),
            renderCell: (info) => <Typography variant="body2">{t(`enum.userStatus.${info.status.toLowerCase()}`)}</Typography>,
          },
        ]}
        data={data}
        sortBy={sortBy}
        onSortChange={onSortChange}
        pagination={pagination}
      />
    </TableContainer>
  );
};

export default TenantUsersList;
