type Props = {
  height?: number;
  width?: number;
};

const CurrentPositionPin = ({ height = 40, width = 40 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="DefaultPin">
      <path
        d="M19.9634 4.1665C13.52 4.1665 8.29669 9.38984 8.29669 15.8332C8.29669 24.1665 19.9634 37.4998 19.9634 37.4998C19.9634 37.4998 31.63 24.1665 31.63 15.8332C31.63 9.38984 26.4067 4.1665 19.9634 4.1665Z"
        fill="#FF2700"
      />
    </svg>
  );
};

export default CurrentPositionPin;
