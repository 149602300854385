import { GeoCoordinates } from '@domain/graphql.types';
import { Geocoder } from '@mapbox/search-js-react';
import { Theme, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const css = (theme: Theme) => {
  return `
    .Geocoder {
      border: 0;
      box-shadow: none;
    }

    .Geocoder .SearchIcon {
      left: 1em;
    }

    .Geocoder .ActionIcon {
      right: 1em;
    }

    .Geocoder .Input {
      font-size: 1rem;
      border: 1px solid ${theme.palette.divider};
      border-radius: ${theme.borderRadius.pill};
      min-height: 44px;
    }

    .Geocoder .Input:focus {
      border-color: ${theme.palette.action.active};
    }

    .MapboxSearch .Results {
      padding: 8px 0;
    }

    .MapboxSearch .Results .SuggestionText {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .MapboxSearch .Results .Suggestion {
      padding: 8px 24px;
    }

    .ResultsAttribution {
      display: none;
    }
  }
`;
};

export type AddressSearchResponse = {
  coordinates: GeoCoordinates;
  fullAddress: string;
  regionCode: string;
};

type Props = {
  onSelect: (address: AddressSearchResponse) => void;
  value?: string;
};

const AddressSearch = forwardRef<HTMLElement, Props>(({ onSelect, value }: Props, ref) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    palette: { text },
    borderRadius,
  } = theme;

  return (
    // @ts-ignore Apparently there is a mismatch in React/types and TSC causing false negatives ¯\_(ツ)_/¯
    <Geocoder
      accessToken={import.meta.env.VITE_REACT_APP_MAP_BOX_PUBLIC_TOKEN}
      onRetrieve={(res) => {
        if (!res.properties.context.region || !('region_code' in res.properties.context.region)) {
          // TODO: add alert once we have one, but this should never happen given how we restrict result types below
          return;
        }

        onSelect({
          coordinates: { latitude: res.geometry.coordinates[1], longitude: res.geometry.coordinates[0] },
          fullAddress: res.properties.full_address,
          regionCode: res.properties.context.region.region_code as string,
        });
      }}
      ref={ref}
      value={value || ''}
      placeholder={t('siteLookup.addressSearch.placeholder')}
      popoverOptions={{ offset: 4 }}
      theme={{
        cssText: css(theme),
        variables: {
          colorPrimary: text.primary,
          colorSecondary: text.secondary,
          colorText: text.primary,
          border: '0',
          borderRadius: borderRadius.lg,
        },
      }}
      options={{ country: 'US', types: 'postcode,district,place,locality,neighborhood,street,address,secondary_address' }}
    />
  );
});

export default AddressSearch;
