import { BulkSearchAddressItem } from '@domain/graphql.types';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ListCard from './ListCard';
import { BulkSearchFilters, OnBulkSearchFilterChange } from '../filters/useBulkSearchFilters';
import StatusFilter from '../filters/StatusFilter';

type Props = {
  addresses: readonly BulkSearchAddressItem[];
  filters: BulkSearchFilters;
  invalidCount: number;
  onFilterChange: OnBulkSearchFilterChange<keyof BulkSearchFilters>;
  totalCount: number;
};

const AddressesList = ({ addresses, filters, invalidCount, onFilterChange, totalCount }: Props) => {
  const { t } = useTranslation();

  const {
    palette: { text },
  } = useTheme();

  const applyFilter = useCallback(
    (address: BulkSearchAddressItem) => {
      if (filters.status === 'all') {
        return true;
      }
      if (filters.status === 'valid') {
        return address.valid === true;
      }
      if (filters.status === 'invalid') {
        return address.valid === false;
      }
    },
    [filters.status],
  );

  return (
    <Stack gap={1.5} paddingBottom={6}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography component="h3" variant="h6" fontWeight={500} sx={{ fontWeight: '600', '&>span': { color: text.secondary } }}>
          {t('bulkSearch.list.addresses', { count: totalCount })}&nbsp;
          {invalidCount > 0 && <span>{t('bulkSearch.list.invalidAddresses', { count: invalidCount })}</span>}
        </Typography>

        <StatusFilter onChange={onFilterChange('status')} value={filters.status} />
      </Box>

      <Stack
        component="ul"
        sx={{
          gap: 2,
          margin: 0,
          paddingLeft: 0,
        }}
      >
        {addresses.filter(applyFilter).map((address, index) => (
          <ListCard address={address} key={address.userDefinedId} isLast={index === addresses.length - 1} />
        ))}
      </Stack>

      {totalCount > 0 && (
        <Typography fontSize={14} fontWeight={500} paddingX={1.5} marginBlockStart={5} textAlign="center">
          {t('siteLookup.list.endReached')}
        </Typography>
      )}
    </Stack>
  );
};

export default AddressesList;
