import { LinkComponent } from '@application/components';
import { UserContext } from '@application/contexts';
import { Routes } from '@application/routes';
import { UserRole } from '@domain/graphql.types';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, Button, ClickAwayListener, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useContext, useRef, useState } from 'react';

const TenantMenu = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const { tenant, tenantSubscription, isOnFreePlan, role } = useContext(UserContext);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const showMenu = role === UserRole.Admin || role === UserRole.Owner;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 2 }}>
        {showMenu ? (
          <Button
            variant="text"
            ref={anchorRef}
            id="tenant-button"
            aria-controls={open ? 'tenant-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
            sx={{ height: '100%', color: 'text.primary', textTransform: 'lowercase' }}
          >
            {tenant?.displayName}
          </Button>
        ) : (
          <Typography variant="body2" color="text.primary" textTransform="lowercase">
            {tenant?.displayName}
          </Typography>
        )}

        <Typography variant="body2" color="text.primary">
          {t(`tenant.remainingDownloads.${isOnFreePlan ? 'free' : 'paid'}`, {
            count: tenantSubscription?.remainingSearches,
          })}
          &nbsp;
          {!!tenantSubscription?.extraSearches && `(${t('tenant.extraDownloads', { count: tenantSubscription.extraSearches })})`}
        </Typography>

        <Menu
          id="tenant-menu"
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          elevation={2}
          MenuListProps={{
            'aria-labelledby': 'tenant-button',
            sx: { minWidth: anchorRef.current?.offsetWidth, paddingY: 0.5 },
          }}
        >
          <ListItem dense sx={{ gap: 1, p: 0 }}>
            <ListItemButton sx={{ px: 1, py: 0 }} href={Routes.TENANT} LinkComponent={LinkComponent} onClick={handleClose}>
              <ListItemIcon sx={{ minWidth: 32 }}>
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText>{t('navigation.settings')}</ListItemText>
            </ListItemButton>
          </ListItem>
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};

export default TenantMenu;
