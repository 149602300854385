import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingComplete from './form/OnboardingCompleted';
import PhoneVerification from './form/PhoneVerification';
import TenantInformation from './form/TenantInformation';
import UserInformation from './form/UserInformation';
import useOnboardingForm from './form/useOnboardingForm';
import LogoParen from '@application/assets/LogoParen';

const OnboardingForm = () => {
  const { t } = useTranslation();

  const {
    actions: { changePhone, sendVerificationCode },
    form: { handleSubmit, Provider },
    state: { isInvitedUser, isVerifying, isSendingVerification, isSubmitting },
    stepper: { activeStep, handleBack, handleContinue },
  } = useOnboardingForm();

  const steps = useMemo(
    () => [
      { label: t('onboarding.steps.password'), component: null },
      { label: t('onboarding.steps.contact'), component: <UserInformation handleContinue={handleContinue} /> },
      { label: t('onboarding.steps.company'), component: <TenantInformation handleContinue={handleContinue} isInvitedUser={isInvitedUser} /> },
      {
        label: t('onboarding.steps.verify'),
        component: (
          <PhoneVerification
            sendVerificationCode={sendVerificationCode}
            changePhone={changePhone}
            isVerifying={isVerifying}
            isSendingVerification={isSendingVerification}
            isSubmitting={isSubmitting}
          />
        ),
      },
    ],
    [changePhone, handleContinue, isInvitedUser, isSendingVerification, isSubmitting, sendVerificationCode, t, isVerifying],
  );

  return (
    <Box width={700}>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" marginBlockEnd={6}>
        <Box height="auto" width="100%" maxWidth={180}>
          <LogoParen full />
        </Box>
      </Box>

      {activeStep < steps.length ? (
        <form onSubmit={handleSubmit}>
          <Provider>
            <Stack gap={6}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Stack gap={1.5} display="grid" gridTemplateColumns="70px 1fr 70px" marginX={2}>
                <Button startIcon={<ArrowBack />} variant="text" sx={{ alignSelf: 'flex-start' }} color="inherit" onClick={handleBack}>
                  {t('button.back')}
                </Button>

                <Stack gap={2} alignItems="center">
                  {steps[activeStep]?.component}
                </Stack>
              </Stack>
            </Stack>
          </Provider>
        </form>
      ) : (
        <OnboardingComplete />
      )}
    </Box>
  );
};

export default OnboardingForm;
