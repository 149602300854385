import { UserContext } from '@application/contexts';
import { Notifications } from '@mui/icons-material';
import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import { Inbox } from '@trycourier/react-inbox';
import { CourierProvider } from '@trycourier/react-provider';
import { useContext } from 'react';
import TenantMenu from './TenantMenu';
import UserMenu from './UserMenu';

const TopBar = () => {
  const { user } = useContext(UserContext);

  const { palette, zIndex } = useTheme();

  return (
    <AppBar
      position="sticky"
      variant="outlined"
      sx={{
        top: '0',
        zIndex: zIndex.appBar,
        background: palette.common.white,
        border: 'none',
        borderBottom: `1px solid ${palette.divider}`,
        '.MuiToolbar-root': { minHeight: 'var(--top-bar-height)' },
      }}
      elevation={0}
    >
      <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginX: 3 }}>
        {user && (
          <>
            <TenantMenu />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <CourierProvider userId={user.externalNotificationId} clientKey={import.meta.env.VITE_REACT_APP_COURIER_CLIENT_KEY}>
                <Inbox
                  renderIcon={() => <Notifications fontSize="small" />}
                  renderFooter={() => null}
                  theme={{
                    header: { backgroundColor: 'white', paddingTop: '16px', paddingBottom: '16px' },
                    messageList: { container: { backgroundColor: 'white' } },
                    unreadIndicator: { backgroundColor: '#FF2700' },
                    message: { icon: { display: 'none' } },
                  }}
                  brand={{
                    colors: { primary: palette.primary.main, secondary: palette.text.secondary, tertiary: palette.error.main },
                    inapp: { borderRadius: '12px', renderActionsAsButtons: true },
                  }}
                  openLinksInNewTab={false}
                />
              </CourierProvider>

              <UserMenu />
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default TopBar;
