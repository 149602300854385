import { MutationInviteTenantUser } from '@infrastructure/graphql';
import { useMutation } from 'urql';
import { Mutation, MutationInviteTenantUserArgs } from '../graphql.types';

const useInviteTenantUserMutation = () => {
  const [{ data, fetching, error }, inviteTenantUser] = useMutation<Pick<Mutation, 'inviteTenantUser'>, MutationInviteTenantUserArgs>(MutationInviteTenantUser);

  return {
    inviteTenantUser,
    data,
    fetching,
    error,
  };
};

export default useInviteTenantUserMutation;
