import { Clear } from '@mui/icons-material';
import { CircularProgress, IconButton, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

type Props = {
  defaultValue?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  placeholder?: string;
  searching?: boolean;
};

const SearchInput = ({ defaultValue, onChange, onClear, placeholder, searching }: Props) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);

      onChange?.(event.target.value);
    },
    [onChange],
  );

  const handleClear = useCallback(() => {
    setValue('');

    onClear?.();
  }, [onClear]);

  const endAdornment = useMemo(() => {
    return searching ? (
      <CircularProgress size={24} sx={{ color: 'action.active', marginRight: 1 }} />
    ) : (
      <IconButton onClick={handleClear} sx={{ display: !value ? 'none' : 'inherit' }}>
        <Clear fontSize="small" />
      </IconButton>
    );
  }, [handleClear, value, searching]);

  return (
    <TextField
      onChange={handleChange}
      sx={{
        minWidth: { xs: 300, md: 400, lg: 500 },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: 'action.active',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: 'action.active',
          },
        },
      }}
      InputProps={{
        endAdornment,
        sx: { borderRadius: '100px', height: 44 },
      }}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default SearchInput;
