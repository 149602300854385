import { RoleGuard } from '@application/guards';
import { PaginationState } from '@application/hooks/usePagination';
import { SortDirection, UserRole } from '@domain/graphql.types';
import {
  Box,
  Table as MUITable,
  styled,
  SxProps,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Column<TData, TSortBy> = {
  label: string;
  renderCell: (info: TData) => React.ReactNode;
  sx?: SxProps;
  onClick?: () => void;
  sortId?: TSortBy;
  roles?: UserRole[];
};

export type TablePaginationProps = {
  currentPage: PaginationState;
  count: number;
  handlePaginationChange: (nextPage: PaginationState) => void;
  handleRowsPerPageChange: (limit: number) => void;
  hideOnSmallTotalCount?: boolean;
  resetPagination: () => void;
};

type TableProps<TData, TSortBy> = {
  columns: Column<TData, TSortBy>[];
  data?: TData[];
  pagination?: TablePaginationProps;
  onSortChange?: (id: TSortBy | undefined) => void;
  sortBy?: { by: TSortBy; direction: SortDirection };
  onRowClick?: (id: string) => void;
};

const Table = <TData extends Record<string, any>, TSortBy>({ columns, data, pagination, onSortChange, sortBy, onRowClick }: TableProps<TData, TSortBy>) => {
  const { t } = useTranslation();

  const direction = useMemo(() => {
    if (sortBy?.direction) {
      return sortBy.direction === SortDirection.Asc ? 'asc' : 'desc';
    }
    return undefined;
  }, [sortBy]);

  const shouldHideFooter = useMemo(() => pagination?.hideOnSmallTotalCount && pagination.count <= pagination.currentPage.pageSize, [pagination]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, & th': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <MUITable sx={{ width: '100%', overflow: 'hidden' }}>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid #ddd` }}>
            {columns.map((column, index) => (
              <RoleGuard key={`${column.label}-${index}`} authorizedRoles={column.roles || Object.values(UserRole)} noRedirect>
                <StyledTableCell sx={{ ...column.sx, fontWeight: 700 }}>
                  {column.sortId ? (
                    <TableSortLabel
                      onClick={() => {
                        if (onSortChange) {
                          onSortChange(column.sortId);
                          if (pagination) {
                            pagination.resetPagination();
                          }
                        }
                      }}
                      active={sortBy?.by === column.sortId}
                      direction={direction}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    <span>{column.label}</span>
                  )}
                </StyledTableCell>
              </RoleGuard>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.map((row, rowIndex) => (
            <StyledTableRow
              key={`${row.id}-${rowIndex}`}
              onClick={(event) => {
                const target = event.target as HTMLElement;
                if (['td', 'span'].includes(target.tagName.toLowerCase()) && onRowClick) {
                  onRowClick(row.id);
                }
              }}
              sx={{
                height: 73,
                minHeight: 73,
                ...(onRowClick && {
                  cursor: 'pointer',
                }),
              }}
            >
              {columns.map((column, colIndex) => (
                <RoleGuard key={`${column.label}-${colIndex}`} authorizedRoles={column.roles || Object.values(UserRole)} noRedirect>
                  <TableCell sx={column.sx}>{column.renderCell(row)}</TableCell>
                </RoleGuard>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </MUITable>

      {pagination?.count === 0 && (
        <Box width="100%" p={2} display="flex" justifyContent="center">
          <Typography variant="body2">{t('list.emptyTable')}</Typography>
        </Box>
      )}

      {pagination && !shouldHideFooter && (
        <TablePagination
          component="div"
          count={pagination.count}
          page={pagination.currentPage.pageIndex}
          onPageChange={(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) =>
            pagination.handlePaginationChange({ pageIndex: page, pageSize: pagination.currentPage.pageSize })
          }
          rowsPerPage={pagination.currentPage.pageSize}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            pagination.handleRowsPerPageChange(parseInt(event.target.value, 10))
          }
          labelRowsPerPage={t('list.pagination.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} ${t('list.pagination.of')} ${count !== -1 ? count : `${t('list.pagination.moreThan')} ${to}`}`
          }
        />
      )}
    </Box>
  );
};

export default Table;
