// https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery?page=1&tab=scoredesc#tab-top
export const downloadFile = (link: string, download?: string) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = link;
  anchor.download = download || '';

  document.body.appendChild(anchor);

  anchor.click();
};
