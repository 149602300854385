import { gql } from '@urql/core';

const MutationSendOtpVerification = gql`
  mutation SendOtpVerification($input: SendOtpVerificationRequest!) {
    sendOtpVerification(input: $input) {
     ok
    }
  }
`;

export default MutationSendOtpVerification;
