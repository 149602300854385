export const getAuth0CurrentLanguage = (currentLanguage?: string | null) => {
  if (currentLanguage?.includes('fr')) {
    return 'fr-CA';
  }

  if (!currentLanguage) {
    return 'en-US';
  }

  return currentLanguage;
};
