export const formatUtcStringDate = (date: string, language: string) =>
  new Intl.DateTimeFormat(language, {
    dateStyle: 'long',
  }).format(new Date(date));

export const formatUtcStringDateTime = (date: string, language: string) =>
  new Intl.DateTimeFormat(language, {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(new Date(date));
