export const borderRadius = {
  default: '8px',
  none: '0',
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '20px',
  pill: '9999px',
  full: '100%',
};
