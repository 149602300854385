import { PageLoader } from '@application/components';
import { useAuthentication } from '@domain/authentication';
import { useEffect } from 'react';

const Home = () => {
  const { loginWithRedirect, isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          ui_locales: 'en',
        },
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  return <PageLoader />;
};
export default Home;
