import { PhoneNumberUtil } from 'google-libphonenumber';

export const isValidPhoneNumber = (phone: string): boolean => {
  const instance = PhoneNumberUtil.getInstance();

  try {
    return instance.isValidNumber(instance.parse(phone));
  } catch {
    // If `google-libphonenumber` can't parse the phone because it's invalid, it will return an error
    return false;
  }
};
