import { gql } from '@urql/core';

const GenerateBulkReport = gql`
  mutation GenerateBulkReport(
    $addresses: [BulkSearchAddressItemInput!]!,
    $aggregation: ReportAggregation!,
  ) {
    generateBulkReport(
      addresses: $addresses,
      aggregation: $aggregation,
    ) {
      report {
        id
      }
    }
  }
`;

export default GenerateBulkReport;
