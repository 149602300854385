import { MapPinpoint } from '@application/assets';
import { NetworkIcon } from '@application/components';
import { rem } from '@application/styles/utils';
import { Site } from '@domain/graphql.types';
import { Box, Chip, Divider, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MapPin from '../components/MapPin';
import SiteLookupContext from '../context/SiteLookupContext';

type Props = {
  isLast: boolean;
  site: Site;
};

const ListCard = forwardRef<HTMLDivElement, Props>(({ isLast, site }: Props, ref) => {
  const { t } = useTranslation();

  const { borderRadius } = useTheme();

  const { selectedSite, setSelectedSite } = useContext(SiteLookupContext);

  const isSelected = selectedSite?.id === site.id;

  return (
    <Stack
      ref={ref}
      paddingBottom={isLast ? 1.5 : 0}
      paddingX={1.5}
      gap={0.5}
      sx={{
        backgroundColor: isSelected ? '#f6f6fd' : 'inherit',
        borderRadius: borderRadius.lg,
        cursor: 'pointer',
        marginTop: isSelected ? '-9px' : '',
        paddingTop: isSelected ? '17px' : 1,
      }}
      onClick={() => setSelectedSite({ id: site.id, origin: 'list' })}
    >
      <Stack direction="row" gap={1}>
        <Tooltip title={t(`enum.networkCode.${site.networkCode}`)} placement="top" arrow>
          <Box>
            <NetworkIcon networkCode={site.networkCode} sx={{ borderRadius: 0.5, height: 44, width: 44 }} />
          </Box>
        </Tooltip>

        <Stack gap={0.5} width="100%">
          <Stack direction="row" alignItems="center">
            <Box
              sx={{
                width: '50%',
              }}
            >
              <Tooltip title={site.name} placement="top" arrow>
                <Typography
                  sx={{
                    marginRight: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: { xs: '9ch', lg: '14ch', xl: '18ch' },
                    width: 'fit-content',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                  }}
                  variant="body1"
                  fontWeight={500}
                >
                  {site.name.toLowerCase()}
                </Typography>
              </Tooltip>
            </Box>

            <MapPinpoint />

            <Typography color="text.secondary" variant="subtitle2" marginLeft={0.5}>
              {t('siteLookup.list.distance', { count: +site.distance.toFixed(2) })}
            </Typography>

            <Box sx={{ height: 20, marginLeft: 'auto' }}>
              <MapPin height={20} state={site.trackingState} status={site.status} width={20} />
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <Chip
              size="small"
              sx={{ backgroundColor: grey[300], borderRadius: 0.5, fontWeight: 'medium', fontSize: rem(13), height: 20, '&>span': { paddingX: '4px' } }}
              label={t('siteLookup.list.ports', { count: site.portsCount })}
            />

            <Chip
              size="small"
              sx={{
                backgroundColor: alpha('#076FDB', 0.13),
                borderRadius: 0.5,
                fontWeight: 'medium',
                color: 'info.dark',
                fontSize: rem(13),
                height: 20,
                '&>span': { paddingX: '4px' },
              }}
              label={t('siteLookup.list.speed', { speed: site.maxSpeed })}
            />
          </Stack>
        </Stack>
      </Stack>

      {!isLast && <Divider sx={{ borderColor: isSelected ? 'transparent' : '', marginTop: 1 }} />}
    </Stack>
  );
});

export default ListCard;
