import { SortDirection } from '@domain/graphql.types';
import { useCallback, useState } from 'react';

type UseSortByProps<TSortBy> = {
  defaultSort?: { by: TSortBy; direction: SortDirection };
};

const useSortBy = <TSortBy,>({ defaultSort }: UseSortByProps<TSortBy>) => {
  const [sortBy, setSortBy] = useState<{ by: TSortBy; direction: SortDirection } | undefined>(defaultSort);

  const handleSortChange = useCallback((id: TSortBy | undefined) => {
    setSortBy((prevSortBy) => {
      if (!prevSortBy || prevSortBy?.by !== id) {
        return { by: id, direction: SortDirection.Asc } as any;
      }

      const direction = prevSortBy.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

      return { by: prevSortBy.by, direction };
    });
  }, []);

  return { sortBy, onSortChange: handleSortChange };
};

export default useSortBy;
