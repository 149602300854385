import { gql } from '@urql/core';

const GenerateSingleAddressSearchReport = gql`
  mutation GenerateSingleAddressSearchReport(
    $input: GenerateSingleAddressSearchReportRequest!,
  ) {
    generateSingleAddressSearchReport(
      input: $input,
    ) {
      link
    }
  }
`;

export default GenerateSingleAddressSearchReport;
