import { Routes } from '@application/routes/routes';
import { useAuthentication } from '@domain/authentication';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type NoAuthGuardProps = {
  children: React.ReactNode;
};

const NoAuthGuard = ({ children }: NoAuthGuardProps) => {
  const { isAuthenticated, isLoading } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to check location
  useEffect(() => {
    if (isAuthenticated) {
      navigate(Routes.SITE_LOOKUP);
    }
  }, [isAuthenticated, navigate, location]);

  if (isLoading) {
    return undefined;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default NoAuthGuard;
