import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InviteTenantUserFormFields } from './InviteTenantUserForm.schema';

type Props = {
  handleClose: () => void;
  handleSubmit: () => void;
  isInvitingUser: boolean;
  open: boolean;
};

const InviteTenantUserModal = ({ handleClose, handleSubmit, isInvitingUser, open }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    register,
  } = useFormContext<InviteTenantUserFormFields>();

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{t('modal.inviteUser.title')}</DialogTitle>

      <DialogContent sx={{ paddingX: 3 }}>
        <Box paddingTop={1}>
          <TextField
            label={t('labels.email')}
            variant="outlined"
            fullWidth
            error={!!errors.email?.message}
            helperText={errors.email?.message && t(errors.email?.message, { field: t('labels.email'), max: 128 })}
            {...register('email')}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ paddingX: 3, paddingBottom: 3 }}>
        <Button variant="outlined" onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          {t('button.cancel')}
        </Button>

        <LoadingButton variant="contained" loading={isInvitingUser} sx={{ alignSelf: 'flex-end' }} type="submit">
          {t('button.sendInvitation')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteTenantUserModal;
