import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

/*
 * This is a special component that is meant to be passed to MUI when it offers a `LinkComponent` props. Otherwise, it will use an anchor and do a full page reload.
 */

type Props = {
  href: string;
  [k: string]: unknown;
};

const LinkComponent = forwardRef<HTMLAnchorElement, Props>(({ href, ...rest }: Props, ref) => <Link ref={ref} to={href} {...rest} />);

export default LinkComponent;
