import { PopoverFilter } from '@application/components/filters';
import { FilterIcon } from '@application/assets';
import { Box, capitalize, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from './useBulkSearchFilters';

type StatusFilterType = {
  onChange: (value: Status) => void;
  value: Status;
};

const StatusFilter = ({ onChange, value }: StatusFilterType) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as Status);
    },
    [onChange],
  );

  return (
    <PopoverFilter
      icon={<FilterIcon />}
      name={t('bulkSearch.filters.status.label')}
      onReset={() => onChange('all')}
      valueLabel={t('bulkSearch.filters.status.buttonLabel', {
        status: capitalize(value),
      })}
      size="medium"
      gap={2}
    >
      <Box minWidth={200} paddingX={1}>
        <RadioGroup name="status">
          <FormControlLabel control={<Radio checked={value === 'all'} onChange={handleChange} />} value="all" label={t('bulkSearch.filters.all')} />
          <FormControlLabel
            control={<Radio checked={value === 'valid'} onChange={handleChange} />}
            value="valid"
            label={t('bulkSearch.filters.status.valid')}
          />
          <FormControlLabel
            control={<Radio checked={value === 'invalid'} onChange={handleChange} />}
            value="invalid"
            label={t('bulkSearch.filters.status.invalid')}
          />
        </RadioGroup>
      </Box>
    </PopoverFilter>
  );
};

export default StatusFilter;
