import { formatUtcStringDateTime } from '@application/utils/date-utils';
import { ReportStatus } from '@domain/graphql.types';
import { MoreHoriz } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  canRegenerate: boolean;
  handleReportDownload: () => void;
  handleReportRegeneration: () => void;
  nextRegenerationDate: string;
  status: ReportStatus;
};

const ActionsMenu = ({ canRegenerate, handleReportDownload, handleReportRegeneration, nextRegenerationDate, status }: Props) => {
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const handleActionsMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchor(event.currentTarget);
  }, []);

  const handleActionsMenuClose = useCallback(() => {
    setActionsMenuAnchor(null);
  }, []);

  return (
    <Box>
      <IconButton onClick={handleActionsMenuClick}>
        <MoreHoriz />
      </IconButton>

      <Menu anchorEl={actionsMenuAnchor} elevation={2} open={!!actionsMenuAnchor} onClose={handleActionsMenuClose}>
        <Tooltip
          disableInteractive={canRegenerate}
          title={canRegenerate ? null : t('reports.list.regenerateOn', { date: formatUtcStringDateTime(nextRegenerationDate, language) })}
          placement="top"
          arrow
        >
          <span>
            <MenuItem disabled={!canRegenerate} onClick={handleReportRegeneration}>
              {t('reports.list.actions.regenerate')}
            </MenuItem>
          </span>
        </Tooltip>

        <MenuItem disabled={status !== ReportStatus.Completed} onClick={handleReportDownload}>
          {t('reports.list.actions.download')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionsMenu;
