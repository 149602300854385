import { ZIndex } from '@mui/material';

export const zIndex: ZIndex = {
  mobileStepper: 10,
  fab: 20,
  speedDial: 20,
  appBar: 30,
  drawer: 40,
  modal: 50,
  snackbar: 60,
  tooltip: 70,
};
