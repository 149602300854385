import { usePagination, useSortBy } from '@application/hooks';
import { flattenEdges } from '@application/utils/data-utils';
import { SortDirection, TenantUserSortBy, TenantUsersFilters, UserStatus } from '@domain/graphql.types';
import { useTenantUsersQuery } from '@domain/tenantusers';
import { useEffect } from 'react';

type UseTenantUsersProps = {
  filterBy?: TenantUsersFilters;
};

const useTenantUsersList = ({ filterBy }: UseTenantUsersProps) => {
  const { sortBy, onSortChange } = useSortBy<TenantUserSortBy>({ defaultSort: { by: TenantUserSortBy.FullName, direction: SortDirection.Asc } });
  const { pagination, currentPage, handlePaginationChange, setCursors, handleRowsPerPageChange, resetPagination } = usePagination();

  const { data, reexecuteQuery } = useTenantUsersQuery({
    filterBy: {
      statuses: [UserStatus.Active, UserStatus.Inactive],
      ...filterBy,
    },
    sortBy: sortBy ? [sortBy] : undefined,
    ...pagination,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: setCursors won't update
  useEffect(() => {
    setCursors({
      startCursor: data?.tenantUsers.page.pageInfo?.startCursor || undefined,
      endCursor: data?.tenantUsers.page.pageInfo?.endCursor || undefined,
    });
  }, [data?.tenantUsers.page.pageInfo]);

  return {
    data: {
      tenantUsers: flattenEdges(data?.tenantUsers.page.edges?.slice() || []),
      count: data?.tenantUsers.pageData?.count || 0,
    },
    pagination: {
      currentPage,
      handlePaginationChange,
      handleRowsPerPageChange,
      resetPagination,
    },
    sorting: {
      onSortChange,
      sortBy,
    },
    actions: {
      refetchTenantUsers: reexecuteQuery,
    },
  };
};

export default useTenantUsersList;
