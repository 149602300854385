import { PersonAddAlt } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  onOpenModal: () => void;
};

// TODO: Move to a generic EmptyState component by passing title, description and action as props
const TenantInvitationsEmptyState = ({ onOpenModal }: Props) => {
  const { palette, typography } = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={2} flexDirection="column" justifyContent="center" alignItems="center" marginBlock={3}>
      <Typography sx={{ fontSize: typography.h4 }}>{t('tenant.inviteUser.emptyState.title')}</Typography>
      <Typography sx={{ fontSize: typography.body1, color: palette.text.secondary }}>{t('tenant.inviteUser.emptyState.description')}</Typography>

      <Button variant="contained" startIcon={<PersonAddAlt />} onClick={onOpenModal}>
        {t('button.inviteUser')}
      </Button>
    </Box>
  );
};

export default TenantInvitationsEmptyState;
