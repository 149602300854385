import { Box } from '@mui/material';
import React from 'react';

type OnboardingLayoutProps = {
  children: React.ReactNode;
};

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '185px',
    }}
  >
    {children}
  </Box>
);

export default OnboardingLayout;
