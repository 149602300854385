import { ReactNode, useMemo, useState } from 'react';
import SiteLookupContext, { SearchLimit, SelectedSite } from './SiteLookupContext';

type Props = {
  children: ReactNode;
};

const SiteLookupProvider = ({ children }: Props) => {
  const [isMapExtended, setIsMapExtended] = useState(true);
  const [selectedSite, setSelectedSite] = useState<SelectedSite | null>(null);
  const [searchLimit, setSearchLimit] = useState<SearchLimit | null>(null);

  const contextValue = useMemo(
    () => ({
      isMapExtended,
      toggleIsMapExtended: () => setIsMapExtended((old) => !old),
      setIsMapExtended,
      selectedSite,
      setSelectedSite,
      searchLimit,
      setSearchLimit,
    }),
    [isMapExtended, searchLimit, selectedSite],
  );

  return <SiteLookupContext.Provider value={contextValue}>{children}</SiteLookupContext.Provider>;
};

export default SiteLookupProvider;
