import { Components, tooltipClasses } from '@mui/material';
import { borderRadius } from './border-radius';
import { palette } from './palette';
import { rem } from './utils';

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overscrollBehavior: 'none',
        scrollBehavior: 'smooth',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        letterSpacing: '0.12px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: borderRadius.sm,
        textTransform: 'initial',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: rem(12),
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: '#FDEDED',
        color: '#5F2120',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: palette.common?.white,
        backgroundColor: palette.common?.black,
        fontSize: rem(14),
        lineHeight: 16 / 12,
      },
      arrow: {
        color: palette.common?.black,
      },
      popper: {
        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
          marginTop: '4px',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 0,
      },
      flexContainer: {
        gap: 'var(--container-gutter)',
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        height: '100% ',
        minHeight: '100%',
        paddingInline: 0.5,
        minWidth: 0,
        fontSize: rem(14),
        textTransform: 'capitalize',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: borderRadius.lg,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textUnderlineOffset: '3px',
      },
    },
  },
};
