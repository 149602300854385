import { Mutation, MutationValidateAddressesArgs } from '@domain/graphql.types';
import { MutationValidateAddresses } from '@infrastructure/graphql';
import { useMutation } from 'urql';

const useValidateAddressesMutation = () => {
  const [result, validateAddresses] = useMutation<Pick<Mutation, 'validateAddresses'>, MutationValidateAddressesArgs>(MutationValidateAddresses);

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    validateAddresses,
  };
};

export default useValidateAddressesMutation;
