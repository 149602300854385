import { Query, QuerySitesArgs } from '@domain/graphql.types';
import { QuerySites } from '@infrastructure/graphql';
import { useQuery } from 'urql';

type Props = {
  variables: QuerySitesArgs;
  pause?: boolean;
};

const useSitesQuery = ({ variables, pause }: Props) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'sites'>, QuerySitesArgs>({
    query: QuerySites,
    variables,
    pause,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useSitesQuery;
