import UserContext from '@application/contexts/UserContext';
import { Routes } from '@application/routes';
import { SubscriptionPlan } from '@domain/graphql.types';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

type PlanGuardProps = React.PropsWithChildren<{
  authorizedPlans?: SubscriptionPlan[] | null;
  noRedirect?: boolean;
}>;

const PlanGuard = ({ children, authorizedPlans, noRedirect }: PlanGuardProps) => {
  const { tenantSubscription } = useContext(UserContext);
  const navigate = useNavigate();

  const isAuthorized = useMemo(
    () => authorizedPlans && tenantSubscription?.plan && authorizedPlans.includes(tenantSubscription.plan),
    [authorizedPlans, tenantSubscription?.plan],
  );

  useEffect(() => {
    if (!isAuthorized && !noRedirect) {
      navigate(Routes.SITE_LOOKUP, { replace: true });
    }
  }, [isAuthorized, noRedirect, navigate]);

  return isAuthorized && (children as any);
};

export default PlanGuard;
