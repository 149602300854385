import { useMemo } from 'react';
import { TenantUsersList } from '../tenant-users-list';
import useTenantUsersList from '../tenant-users-list/useTenantUsersList';

const useTenantUsers = () => {
  const {
    data: { count, tenantUsers },
    pagination: { currentPage, handlePaginationChange, handleRowsPerPageChange, resetPagination },
    sorting: { onSortChange, sortBy },
  } = useTenantUsersList({});

  const List = useMemo(
    () => () => (
      <TenantUsersList
        data={tenantUsers}
        onSortChange={onSortChange}
        pagination={{ count, currentPage, handlePaginationChange, handleRowsPerPageChange, resetPagination, hideOnSmallTotalCount: true }}
        sortBy={sortBy}
      />
    ),
    [tenantUsers, onSortChange, sortBy, count, currentPage, handlePaginationChange, handleRowsPerPageChange, resetPagination],
  );

  return {
    List,
  };
};

export default useTenantUsers;
