import { gql } from '@urql/core';
import { UserWithDefaultTenant } from '../fragments';

const QueryMe = gql`
  ${UserWithDefaultTenant}

  query Me {
    me {
      user {
        ...UserWithDefaultTenantFragment
      }
    }
  }
`;

export default QueryMe;
