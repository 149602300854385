import { useCallback, useState } from 'react';

export type Status = 'valid' | 'invalid' | 'all';

export type BulkSearchFilters = {
  status: Status;
};

export type OnBulkSearchFilterChange<T extends keyof BulkSearchFilters> = (key: T) => (value: BulkSearchFilters[T]) => void;

const useBulkSearchFilters = () => {
  const [filters, setFilters] = useState<BulkSearchFilters>({
    status: 'all',
  });

  const updateFilter = useCallback(
    <T extends keyof BulkSearchFilters>(key: T) =>
      (value: BulkSearchFilters[T]) => {
        setFilters((old) => ({ ...old, [key]: value }));
      },
    [],
  );

  return { filters, updateFilter };
};

export default useBulkSearchFilters;
