import * as yup from 'yup';

export const INVITE_TENANT_USER_SCHEMA = yup.object({
  email: yup
    .string()
    .transform((_, val) => val || undefined)
    .max(128, 'validations.textInput.maxLength')
    .required('validations.required.email')
    .email('validations.validEmail'),
});

export type InviteTenantUserFormFields = yup.InferType<typeof INVITE_TENANT_USER_SCHEMA>;
