import { UserContext } from '@application/contexts';
import { Box, MenuItem, Typography } from '@mui/material';
import { useContext } from 'react';
import UserAvatar from './UserAvatar';

const UserHeaderMenuItem = () => {
  const { user } = useContext(UserContext);
  const { email, displayName, defaultTenant } = user || {};
  const name = displayName || email || '';
  const tenantName = defaultTenant?.displayName || defaultTenant?.slug || '';

  return (
    <MenuItem disabled disableRipple dense style={{ opacity: 1 }}>
      <Box display="flex" flexDirection="row" gap={2}>
        <Box display="flex" alignSelf="center">
          <UserAvatar />
        </Box>
        <Box display="flex" flexDirection="column" gap={0}>
          {name && (
            <Typography variant="body1" color="black" sx={{ fontWeight: 'bold' }}>
              {name}
            </Typography>
          )}
          {email && (
            <Typography variant="body2" color="black">
              {email}
            </Typography>
          )}
          {tenantName && (
            <Typography variant="body2" color="grey">
              {tenantName}
            </Typography>
          )}
        </Box>
      </Box>
    </MenuItem>
  );
};

export default UserHeaderMenuItem;
