import { useTheme } from '@mui/material';

type Props = {
  height?: number;
  width?: number;
};

const TrackedPin = ({ height = 40, width = 40 }: Props) => {
  const { palette } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="TrackedPin">
      <g clipPath="url(#clip0_4507_34617)">
        <path
          d="M20 3.33301C13.5566 3.33301 8.33331 8.55634 8.33331 14.9997C8.33331 23.333 20 36.6663 20 36.6663C20 36.6663 31.6666 23.333 31.6666 14.9997C31.6666 8.55634 26.4433 3.33301 20 3.33301Z"
          fill={palette.primary.main}
        />
        <path d="M20.2564 8.33301L16.6666 16.2118H19.7436V21.6663L23.3333 13.7876H20.2564V8.33301Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_4507_34617">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrackedPin;
