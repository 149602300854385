import { gql } from '@urql/core';

const ValidateAddresses = gql`
  mutation ValidateAddresses($files: [Upload!]!) {
  validateAddresses(files: $files) {
    addresses {
      userDefinedId
      name
      fullAddress
      coordinates {
        latitude
        longitude
      }
      stateCode
      valid
    }
    invalidCount
    totalCount
  }
}
`;

export default ValidateAddresses;
