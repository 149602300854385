import { Query, QueryTenantBillingCustomerSecretArgs } from '@domain/graphql.types';
import { QueryTenantBillingCustomerSecret } from '@infrastructure/graphql';
import { useQuery } from 'urql';

const useTenantBillingCustomerSecretQuery = (input: QueryTenantBillingCustomerSecretArgs) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'tenantBillingCustomerSecret'>, QueryTenantBillingCustomerSecretArgs>({
    query: QueryTenantBillingCustomerSecret,
    variables: input,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useTenantBillingCustomerSecretQuery;
