import { rem } from '@application/styles/utils';
import { Close, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Typography, alpha, useTheme } from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactNode;
  icon?: ReactNode;
  name: string;
  onReset?: () => void;
  readonly?: boolean;
  size?: 'small' | 'medium' | 'large';
  gap?: number;
  valueLabel?: string;
};

const PopoverFilter = ({ icon, name, children, onReset, readonly, valueLabel, size = 'large', gap = 5 }: Props) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const { t } = useTranslation();

  const { palette, typography } = useTheme();

  const handleFilterOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const open = !!anchorElement;

  const showValueLabel = valueLabel && !open;

  const endIcon = useMemo(() => {
    if (readonly) {
      return null;
    }

    return open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />;
  }, [open, readonly]);

  return (
    <>
      <Button
        disabled={readonly}
        endIcon={endIcon}
        onClick={handleFilterOpen}
        size={size}
        startIcon={icon}
        sx={{
          color: showValueLabel ? 'primary.dark' : 'inherit',
          fontSize: typography.body2,
          fontWeight: typography.fontWeightMedium,
          paddingX: '12px',
          backgroundColor: showValueLabel ? alpha(palette.primary.light, 0.1) : 'inherit',
          '&.Mui-disabled': {
            backgroundColor: '#F0F0F0',
            borderColor: '#716B75',
            color: '#716B75',
          },
        }}
        variant="outlined"
      >
        {showValueLabel ? valueLabel : name}
      </Button>

      <Popover
        sx={{
          marginTop: 0.5,
          '& .MuiPopover-paper': {
            borderRadius: '12px',
          },
        }}
        anchorEl={anchorElement}
        onClose={handleFilterClose}
        open={open}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2} display="flex" flexDirection="column" gap={gap}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize={rem(18)} fontWeight="medium">
              {name}
            </Typography>

            <IconButton sx={{ p: 0 }} onClick={handleFilterClose}>
              <Close />
            </IconButton>
          </Box>

          {children}

          {onReset && (
            <Button onClick={onReset} sx={{ alignSelf: 'end' }}>
              {t('button.reset')}
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default PopoverFilter;
