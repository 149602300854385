import { Pagination } from '@domain/pagination/pagination';
import { useCallback, useState } from 'react';

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 10;

export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

type CursorsState = {
  startCursor?: string;
  endCursor?: string;
};

const usePagination = () => {
  const [cursors, setCursors] = useState<CursorsState>();
  const [pagination, setPagination] = useState<Pagination>({ first: DEFAULT_PAGE_SIZE });
  const [currentPage, setCurrentPage] = useState<PaginationState>({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });

  const resetPagination = useCallback(() => {
    setCurrentPage({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
    setPagination({ first: DEFAULT_PAGE_SIZE });
  }, []);

  const handlePaginationChange = useCallback(
    (nextPage: PaginationState): void => {
      const nextPagination: Pagination = {};

      if (nextPage.pageIndex > currentPage.pageIndex) {
        nextPagination.first = nextPage.pageSize;
        nextPagination.after = cursors?.endCursor;
      } else if (nextPage.pageIndex < currentPage.pageIndex) {
        nextPagination.last = nextPage.pageSize;
        nextPagination.before = cursors?.startCursor;
      }

      setPagination(nextPagination);
      setCurrentPage(nextPage);
    },
    [currentPage, cursors],
  );

  const handleRowsPerPageChange = useCallback((limit: number): void => {
    setPagination({ first: limit });
    setCurrentPage({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: limit });
  }, []);

  return {
    currentPage,
    handlePaginationChange,
    handleRowsPerPageChange,
    pagination,
    resetPagination,
    setCurrentPage,
    setCursors,
  };
};

export default usePagination;
