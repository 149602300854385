const CaretIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.25 -0.25 24 24" height="24" width="24" role="img" aria-label="CaretIcon">
    <g>
      <path
        d="m0.8392857142857142 6.7142857142857135 10.317255357142855 10.317171428571427c0.3277578571428571 0.327825 0.8591599999999998 0.327825 1.186917857142857 0L22.660714285714285 6.7142857142857135"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </g>
  </svg>
);

export default CaretIcon;
