import { Table } from '@application/components/table';
import { TablePaginationProps } from '@application/components/table/Table';
import { formatUtcStringDate } from '@application/utils/date-utils';
import { SortDirection, TenantUserSortBy, User } from '@domain/graphql.types';
import { PersonAddAlt } from '@mui/icons-material';
import { Button, Stack, TableContainer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: User[];
  pagination?: TablePaginationProps;
  onOpenModal?: () => void;
  onSortChange?: (id: TenantUserSortBy | undefined) => void;
  sortBy?: { by: TenantUserSortBy; direction: SortDirection };
};

const TenantInvitationsList = ({ data, onOpenModal, onSortChange, pagination, sortBy }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Stack gap={4}>
      <Button
        variant="contained"
        startIcon={<PersonAddAlt />}
        onClick={onOpenModal}
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        {t('button.inviteUser')}
      </Button>

      <TableContainer>
        <Table
          columns={[
            {
              label: t('list.header.email'),
              renderCell: (info) => (
                <Typography variant="body2" component="span">
                  {info.email}
                </Typography>
              ),
              sortId: TenantUserSortBy.Email,
            },
            {
              label: t('list.header.role'),
              renderCell: (info) => <Typography variant="body2">{info.role ? t(`enum.userRole.${info.role.toLowerCase()}`) : '-'}</Typography>,
            },
            {
              label: t('list.header.invitedAt'),
              renderCell: (info) => <Typography variant="body2">{formatUtcStringDate(info.createdAt, i18n.language)}</Typography>,
            },
          ]}
          data={data}
          sortBy={sortBy}
          onSortChange={onSortChange}
          pagination={pagination}
        />
      </TableContainer>
    </Stack>
  );
};

export default TenantInvitationsList;
