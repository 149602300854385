import { Query, QueryReportsArgs } from '@domain/graphql.types';
import { QueryReports } from '@infrastructure/graphql';
import { useQuery } from 'urql';

type Props = {
  variables: QueryReportsArgs;
  pause?: boolean;
};

const useReportsQuery = ({ variables, pause }: Props) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'reports'>, QueryReportsArgs>({
    query: QueryReports,
    variables,
    pause,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useReportsQuery;
