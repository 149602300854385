import { UserContext } from '@application/contexts';
import { AccountCircle } from '@mui/icons-material';
import { Avatar, useTheme } from '@mui/material';
import { useContext, useMemo } from 'react';

const UserAvatar = () => {
  const { typography } = useTheme();

  const { user } = useContext(UserContext);
  const { displayName, firstName, lastName } = user || {};

  const avatar = useMemo(() => {
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`.toUpperCase();
    }

    if (displayName && displayName.length >= 2) {
      return `${displayName[0].toUpperCase()}${displayName[1]}`;
    }

    return <AccountCircle />;
  }, [firstName, lastName, displayName]);

  return (
    <Avatar title={displayName || ''} sx={{ width: 40, height: 40, fontSize: typography.body1 }}>
      {avatar}
    </Avatar>
  );
};

export default UserAvatar;
