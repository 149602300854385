import { UserContext } from '@application/contexts';
import { Routes } from '@application/routes';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type OnboardingGuardProps = {
  children: React.ReactNode;
};

const OnboardingGuard = ({ children }: OnboardingGuardProps) => {
  const { hasFinishedOnboarding } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (hasFinishedOnboarding) {
      navigate(Routes.SITE_LOOKUP);
      return;
    }

    navigate(Routes.ONBOARDING);
  }, [hasFinishedOnboarding, navigate]);

  if (hasFinishedOnboarding) {
    return false;
  }

  // cast as JSX element to remove type error in router file
  return children as React.JSX.Element;
};

export default OnboardingGuard;
