import { gql } from 'urql';

const ReportFragment = gql`
  fragment ReportFragment on Report {
    id
    aggregation
    canRegenerate
    userId
    user {
      displayName
    }
    addresses
    filters {
      range
    }
    createdAt
    status
    name
    nextRegenerationDate
    type
  }
`;

export default ReportFragment;
