import { lighten, useTheme } from '@mui/material';

type Props = {
  height?: number;
  width?: number;
};

const UntrackedPin = ({ height = 40, width = 40 }: Props) => {
  const { palette } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="UntrackedPin">
      <g clipPath="url(#clip0_4507_35984)">
        <path
          d="M19.9634 3.33301C13.52 3.33301 8.33331 8.55634 8.33331 14.9997C8.33331 23.333 19.9634 36.6663 19.9634 36.6663C19.9634 36.6663 31.6666 23.333 31.6666 14.9997C31.6666 8.55634 26.4067 3.33301 19.9634 3.33301Z"
          fill={lighten(palette.primary.main, 0.1)}
        />
        <path
          d="M20 6.66634C15.3991 6.66634 11.6666 10.3988 11.6666 14.9997C11.6666 19.6005 15.3991 23.333 20 23.333C24.6008 23.333 28.3333 19.6005 28.3333 14.9997C28.3333 10.3988 24.6008 6.66634 20 6.66634ZM19.5658 21.6797V16.4526H16.66L20.835 8.31965V13.5468H23.6322L19.5658 21.6797Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4507_35984">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UntrackedPin;
