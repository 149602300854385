type Props = {
  height?: number;
  width?: number;
};

const LegendIcon = ({ height = 24, width = 24 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="LegendIcon">
      <path d="M7.23529 17H21V20H7.23529V17Z" fill="#09050F" fillOpacity="0.56" />
      <path
        d="M4.58824 16C5.46539 16 6.17647 16.7835 6.17647 17.75C6.17647 19 4.58824 21 4.58824 21C4.58824 21 3 19 3 17.75C3 16.7835 3.71108 16 4.58824 16Z"
        fill="#09050F"
        fillOpacity="0.56"
      />
      <path d="M7.23529 11H21V14H7.23529V11Z" fill="#09050F" fillOpacity="0.56" />
      <path
        d="M4.58824 10C5.46539 10 6.17647 10.7835 6.17647 11.75C6.17647 13 4.58824 15 4.58824 15C4.58824 15 3 13 3 11.75C3 10.7835 3.71108 10 4.58824 10Z"
        fill="#09050F"
        fillOpacity="0.56"
      />
      <path d="M7.23529 5H21V8H7.23529V5Z" fill="#09050F" fillOpacity="0.56" />
      <path
        d="M4.58824 4C5.46539 4 6.17647 4.7835 6.17647 5.75C6.17647 7 4.58824 9 4.58824 9C4.58824 9 3 7 3 5.75C3 4.7835 3.71108 4 4.58824 4Z"
        fill="#09050F"
        fillOpacity="0.56"
      />
    </svg>
  );
};

export default LegendIcon;
