import { QueryMe } from '@infrastructure/graphql';
import { useQuery } from 'urql';
import { Query } from '../graphql.types';

const useMeQuery = ({ pause }: { pause?: boolean } = {}) => {
  const [result, reexecuteQuery] = useQuery<Pick<Query, 'me'>, void>({
    query: QueryMe,
    pause,
  });

  const { data, fetching, error } = result;

  return {
    data,
    fetching,
    error,
    reexecuteQuery,
  };
};

export default useMeQuery;
