import { Mutation, MutationDownloadReportArgs } from '@domain/graphql.types';
import { MutationDownloadReport } from '@infrastructure/graphql';
import { useMutation } from 'urql';

const useDownloadReportMutation = () => {
  const [{ data, fetching, error }, downloadReport] = useMutation<Pick<Mutation, 'downloadReport'>, MutationDownloadReportArgs>(MutationDownloadReport);

  return {
    data,
    downloadingReport: fetching,
    error,
    downloadReport,
  };
};

export default useDownloadReportMutation;
