type Props = {
  height?: number;
  width?: number;
};

const CsvIcon = ({ height = 37, width = 27 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="CsvIcon">
      <path
        d="M0 2.22C0 1.92846 0.0540437 1.63978 0.159046 1.37044C0.264049 1.1011 0.417954 0.856368 0.611974 0.650222C0.805993 0.444076 1.03633 0.280552 1.28983 0.168987C1.54333 0.0574215 1.81502 0 2.08941 0H17.4117L26.1176 7.76999V34.78C26.1176 35.3687 25.8975 35.9334 25.5056 36.3497C25.1138 36.7661 24.5824 37 24.0282 37H2.08941C1.81502 37 1.54333 36.9425 1.28983 36.831C1.03633 36.7194 0.805993 36.5559 0.611974 36.3497C0.417954 36.1436 0.264049 35.8989 0.159046 35.6295C0.0540437 35.3602 0 35.0715 0 34.78V2.22Z"
        fill="#02733C"
      />
      <path d="M17.4119 0V5.54999C17.4119 6.13877 17.632 6.70344 18.0239 7.11977C18.4157 7.5361 18.9472 7.76999 19.5013 7.76999H26.1178" fill="white" />
      <path
        d="M8.70692 19.8974C8.29339 19.5389 7.8581 19.3192 7.28079 19.3192C6.24697 19.3192 5.50697 20.1402 5.50697 22.0948C5.50697 24.0148 6.20344 24.8357 7.23726 24.8357C7.97726 24.8357 8.4452 24.512 8.86961 24.1651L9.68579 25.2751C9.13079 25.8416 8.31461 26.3157 7.11756 26.3157C5.03848 26.3157 3.62378 24.8473 3.62378 22.0954C3.62378 19.4118 5.13643 17.8965 7.14966 17.8965C8.23789 17.8965 8.95722 18.2434 9.58839 18.7874L8.70692 19.8974Z"
        fill="white"
      />
      <path
        d="M12.9293 26.3157C11.5576 26.3157 10.5346 25.807 9.91434 25.1595L10.8285 24.0841C11.3835 24.6045 12.0582 24.8935 12.8635 24.8935C13.6035 24.8935 14.1051 24.5351 14.1051 23.9223C14.1051 23.3442 13.7895 23.0898 12.7448 22.7423C11.014 22.1642 10.3502 21.4358 10.3502 20.2327C10.3502 18.7521 11.6343 17.8965 13.2013 17.8965C14.3451 17.8965 15.2374 18.278 15.8904 18.9371L15.041 19.9783C14.5295 19.5274 13.9305 19.3071 13.3428 19.3071C12.6681 19.3071 12.2214 19.573 12.2214 20.0818C12.2214 20.5905 12.5587 20.7871 13.7123 21.1802C15.1498 21.6774 15.9878 22.3018 15.9878 23.7708C15.9878 25.2745 14.8342 26.3157 12.9293 26.3157Z"
        fill="white"
      />
      <path d="M19.5893 24.5258H19.6437L21.0263 18.1069H22.8763L20.7102 26.1215H18.4679L16.3126 18.1069H18.2285L19.5893 24.5258Z" fill="white" />
    </svg>
  );
};

export default CsvIcon;
