import { CsvIcon } from '@application/assets';
import { downloadFile } from '@application/utils/file-utils';
import csvTemplateUrl from '@assets/bulk/BulkSearchTemplate.csv?url';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BulkTemplate = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={1} padding={2} sx={{ backgroundColor: '#F5F5F5', borderRadius: 2 }} alignItems="center">
      <Stack gap={1} maxWidth="75%">
        <Stack direction="row" gap={1} alignItems="center">
          <CsvIcon width={14} height={19} />

          <Typography variant="body2" fontWeight={500}>
            {t('bulkSearch.template.title')}
          </Typography>
        </Stack>

        <Typography color="text.secondary" variant="body2">
          {t('bulkSearch.template.description')}
        </Typography>
      </Stack>

      <Button variant="outlined" color="inherit" onClick={() => downloadFile(csvTemplateUrl, 'BulkTemplate')} size="small" sx={{ marginLeft: 'auto' }}>
        {t('bulkSearch.template.download')}
      </Button>
    </Box>
  );
};

export default BulkTemplate;
