import {
  ABMSvg,
  AmpUpSvg,
  BPPulseSvg,
  BlinkSvg,
  ChargeLabSvg,
  ChargeNetSvg,
  ChargePointSvg,
  ChargeUpSvg,
  ChargieSvg,
  CircleKSvg,
  EVCSSvg,
  EVConnectSvg,
  EVGatewaySvg,
  EVPowerSvg,
  EVRangeSvg,
  EVgoSvg,
  ElectrifyAmericaSvg,
  FPLEVSvg,
  FloSvg,
  FrancisEnergySvg,
  GMUltiumSvg,
  GravitiSvg,
  JuleSvg,
  LivingstonSvg,
  LoopSvg,
  MercedesBenzSvg,
  NonNetworkedSvg,
  NoodoeSvg,
  OPConnectSvg,
  PowerNodeSvg,
  PowerflexSvg,
  RedESvg,
  RevelSvg,
  RivianSvg,
  SevenChargeSvg,
  ShellRechargeSvg,
  SwtchSvg,
  TeslaSvg,
  UniversalSvg,
  VoltaSvg,
  WaveSvg,
  ZefNetSvg,
} from '@application/assets';
import { NetworkCode } from '@domain/graphql.types';
import { Box, SxProps, Theme } from '@mui/material';

const networkIconMap = new Map([
  [NetworkCode.Abm, ABMSvg],
  [NetworkCode.Ampup, AmpUpSvg],
  [NetworkCode.Blink, BlinkSvg],
  [NetworkCode.BpPulse, BPPulseSvg],
  [NetworkCode.Chargelab, ChargeLabSvg],
  [NetworkCode.Chargenet, ChargeNetSvg],
  [NetworkCode.ChargePoint, ChargePointSvg],
  [NetworkCode.Chargeup, ChargeUpSvg],
  [NetworkCode.Chargie, ChargieSvg],
  [NetworkCode.CircleK, CircleKSvg],
  [NetworkCode.ElectrifyAmerica, ElectrifyAmericaSvg],
  [NetworkCode.EvConnect, EVConnectSvg],
  [NetworkCode.Evcs, EVCSSvg],
  [NetworkCode.Evgateway, EVGatewaySvg],
  [NetworkCode.Evgo, EVgoSvg],
  [NetworkCode.EvPower, EVPowerSvg],
  [NetworkCode.EvRange, EVRangeSvg],
  [NetworkCode.Flo, FloSvg],
  [NetworkCode.Fplev, FPLEVSvg],
  [NetworkCode.Fcn, FrancisEnergySvg],
  [NetworkCode.GmUltium, GMUltiumSvg],
  [NetworkCode.GravitiEnergy, GravitiSvg],
  [NetworkCode.Jule, JuleSvg],
  [NetworkCode.Livingston, LivingstonSvg],
  [NetworkCode.Loop, LoopSvg],
  [NetworkCode.MercedesBenz, MercedesBenzSvg],
  [NetworkCode.Noodoe, NoodoeSvg],
  [NetworkCode.OpConnect, OPConnectSvg],
  [NetworkCode.Powerflex, PowerflexSvg],
  [NetworkCode.PowerNode, PowerNodeSvg],
  [NetworkCode.RedE, RedESvg],
  [NetworkCode.Revel, RevelSvg],
  [NetworkCode.RivianAdventure, RivianSvg],
  [NetworkCode.SevenCharge, SevenChargeSvg],
  [NetworkCode.ShellRecharge, ShellRechargeSvg],
  [NetworkCode.Swtch, SwtchSvg],
  [NetworkCode.Tesla, TeslaSvg],
  [NetworkCode.Universal, UniversalSvg],
  [NetworkCode.Volta, VoltaSvg],
  [NetworkCode.Wave, WaveSvg],
  [NetworkCode.Zefnet, ZefNetSvg],
  [NetworkCode.NonNetworked, NonNetworkedSvg],
]);

type Props = {
  networkCode: NetworkCode;
  sx?: SxProps<Theme>;
};

const NetworkIcon = ({ networkCode, sx }: Props) => {
  const iconSrc = networkIconMap.get(networkCode) || NonNetworkedSvg;

  return <Box sx={sx} component="img" alt={networkCode} src={iconSrc} />;
};

export default NetworkIcon;
