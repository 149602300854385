import { UserContext } from '@application/contexts';
import { useTenantBillingCustomerSecretQuery } from '@domain/index';
import { useContext } from 'react';

const usePricingTable = () => {
  const { user } = useContext(UserContext);

  const { data, fetching } = useTenantBillingCustomerSecretQuery({ input: { tenantId: user?.defaultTenantId || '' } });

  return {
    key: import.meta.env.VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY,
    loadingSecret: fetching,
    pricingTableId: import.meta.env.VITE_REACT_APP_STRIPE_PRICING_TABLE_ID,
    secret: data?.tenantBillingCustomerSecret.secret,
  };
};

export default usePricingTable;
