import { useTenantInvitations } from './hooks';
import InviteTenantUserModal from './invite-tenant-user-modal/InviteTenantUserModal';

const TenantInvitations = () => {
  const {
    actions: { handleCloseModal, handleSubmit },
    renderTenantInvitations,
    form: { Provider },
    state: { isInvitingUser, isModalOpen },
  } = useTenantInvitations();

  return (
    <>
      {renderTenantInvitations()}

      <Provider>
        <InviteTenantUserModal handleClose={handleCloseModal} handleSubmit={handleSubmit} isInvitingUser={isInvitingUser} open={isModalOpen} />
      </Provider>
    </>
  );
};

export default TenantInvitations;
