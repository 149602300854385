import { Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OnboardingSchemaFormFields } from './OnboardingForm.schema';

type Props = {
  handleContinue: (keys: (keyof OnboardingSchemaFormFields)[]) => () => void;
};

const UserInformation = ({ handleContinue }: Props) => {
  const { t } = useTranslation();

  const { typography } = useTheme();

  const {
    register,
    formState: { errors },
  } = useFormContext<OnboardingSchemaFormFields>();

  return (
    <>
      <Stack gap={3} alignItems="center">
        <Typography variant="h1" sx={{ marginTop: 0.5, fontSize: typography.h3, fontWeight: typography.fontWeightMedium }}>
          {t('onboarding.userInformation.title')}
        </Typography>

        <Typography variant="body1">{t('onboarding.userInformation.subscript')}</Typography>

        <Stack gap={2} width={400}>
          <TextField
            label={t('labels.firstName')}
            variant="outlined"
            fullWidth
            error={!!errors.firstName?.message}
            helperText={errors.firstName?.message && t(errors.firstName?.message, { field: t('labels.firstName'), max: 128 })}
            {...register('firstName')}
          />

          <TextField
            label={t('labels.lastName')}
            variant="outlined"
            fullWidth
            error={!!errors.lastName?.message}
            helperText={errors.lastName?.message && t(errors.lastName?.message, { field: t('labels.lastName'), max: 128 })}
            {...register('lastName')}
          />

          <TextField
            label={t('labels.position')}
            variant="outlined"
            fullWidth
            error={!!errors.position?.message}
            helperText={
              errors.position?.message
                ? t(errors.position?.message, { field: t('labels.position'), max: 128 })
                : t('onboarding.userInformation.positionInformation')
            }
            {...register('position')}
          />

          <Button variant="contained" size="large" fullWidth onClick={handleContinue(['firstName', 'lastName', 'position'])}>
            {t('button.continue')}
          </Button>
        </Stack>
      </Stack>

      <Typography variant="body2" textAlign="center">
        {t('onboarding.userInformation.termsAndConditions')}
      </Typography>
    </>
  );
};

export default UserInformation;
