import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Box, Button, useTheme } from '@mui/material';

type Props = {
  handleToggleMapExtended: () => void;
  isMapExtended: boolean;
};

const ExtendMapControl = ({ handleToggleMapExtended, isMapExtended }: Props) => {
  const { borderRadius } = useTheme();

  return (
    <Box sx={{ position: 'absolute', bottom: '40px', left: '24px', zIndex: 50, boxShadow: 1, borderRadius: borderRadius.sm }}>
      <Button
        size="small"
        sx={{ backgroundColor: 'white', paddingX: 0, minHeight: 40, minWidth: 40 }}
        color="inherit"
        variant="contained"
        onClick={handleToggleMapExtended}
      >
        {isMapExtended ? <KeyboardArrowRightOutlined /> : <KeyboardArrowLeftOutlined />}
      </Button>
    </Box>
  );
};

export default ExtendMapControl;
