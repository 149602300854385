import Spinner from '@application/components/spinner/Spinner';
import styles from './PageLoader.module.css';

const PageLoader = () => (
  <div className={styles['page-loader']}>
    <Spinner />
  </div>
);

export default PageLoader;
