import { gql } from 'urql';

const UserWithDefaultTenant = gql`
  fragment UserWithDefaultTenantFragment on User {
    id
    defaultTenantId
    defaultTenant {
      id
      address
      displayName
      ownerId
      slug
      freeSearches
      subscription {
        plan
        remainingSearches
        extraSearches
        amountPerExtraSearch
        allowedAggregations
      }
    }
    email
    phone
    isEmailVerified
    firstName
    lastName
    displayName
    language
    externalNotificationId
    hasCompletedOnboarding
    role
    status
    createdAt
    version
  }
`;

export default UserWithDefaultTenant;
