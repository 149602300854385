import { Mutation, MutationRegenerateBulkReportArgs } from '@domain/graphql.types';
import { MutationRegenerateBulkReport } from '@infrastructure/graphql';
import { useMutation } from 'urql';

const useRegenerateBulkReportMutation = () => {
  const [{ data, fetching, error }, regenerateBulkReport] = useMutation<Pick<Mutation, 'regenerateBulkReport'>, MutationRegenerateBulkReportArgs>(
    MutationRegenerateBulkReport,
  );

  return {
    data,
    generatingReport: fetching,
    error,
    regenerateBulkReport,
  };
};

export default useRegenerateBulkReportMutation;
