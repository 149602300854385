export enum Routes {
  BULK_SEARCH = '/bulk-search',
  DASHBOARD = '/dashboard',
  GETTING_STARTED = '/getting-started',
  HOME = '/',
  ONBOARDING = '/onboarding',
  REPORTS = '/reports',
  SITE_LOOKUP = '/site-lookup',
  TENANT = '/tenant',
  TENANT_INVITATIONS = '/tenant/invitations',
  TENANT_USERS = '/tenant/users',
  TENANT_SUBSCRIPTION = '/tenant/subscription',
}

export default Routes;
